import React from "react";
import { Link } from "react-router-dom";
import { Col, Card, CardBody, Row } from "reactstrap";
// import ApexRadial from "./ApexRadial";

// import users from "../assets/images/small/profile.png";
import small from "../../../assets/images/small/placeholder-img.png"

const InstaProfilePlaceholder = () => {
    return (
        <React.Fragment>
            
            <Col xl={12}>
                <Card className="card-h-100">
                    <CardBody>
                        <div className="d-flex flex-wrap align-items-center mb-4">
                        </div>
                        <div className="ms-auto">
                            <h2 className="mb-1 text-center placeholder-glow">{""}<span className="placeholder col-2"></span></h2>
                        </div>

                        <Row className="align-items-center">
                            <div className="col-sm placeholder-glow">
                                <div id="" className="position-relative mb-3 text-center">
                                    <img
                                        src={small}
                                        alt=""
                                        className="img-thumbnail rounded-circle"
                                    />
                                </div>
                            </div>


                            <div className="col-sm align-self-center">
                                <div className="mt-4 mt-sm-0">
                                    <Row className="g-0">
                                        <Col xs={4}>
                                            <div className=" placeholder-glow">
                                                <p className="mb-2 text-capitalize font-size-18">
                                                    {""}
                                                </p>
                                                <span className="placeholder col-7"></span>
                                                <h5 className="fw-medium">{""}</h5>
                                                <span className="placeholder col-3"></span>
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <div className=" placeholder-glow">
                                                <p className="mb-2 text-capitalize font-size-18">
                                                    {""}
                                                </p>
                                                <span className="placeholder col-7"></span>
                                                <h5 className="fw-medium">{""}</h5>
                                                <span className="placeholder col-3"></span>
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <div className=" placeholder-glow">
                                                <p className="mb-2 text-capitalize font-size-18">
                                                    {""}
                                                </p>
                                                <span className="placeholder col-7"></span>
                                                <h5 className="fw-medium">{""}</h5>
                                                <span className="placeholder col-3"></span>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="mt-2 placeholder-glow">
                                        <h4 className="mb-2 text-capitalize font-size-16">
                                            {""}
                                        </h4>
                                        <span className="placeholder col-3"></span>
                                        <p className="biodata">{""}</p>
                                        <span className="placeholder col-12"></span>
                                        <span className="placeholder col-12"></span>
                                        <span className="placeholder col-2"></span>
                                        {/* <p className="text-muted">{""} <span className="placeholder col-5"></span><span className="text-black">{"siddhant_gupta___"}</span></p> */}
                                        <span className="placeholder col-3"></span>
                                        <p></p>
                                        <Link to="#" className="btn btn-primary disabled placeholder col-6"></Link>
                                        {/* <Link to="/email-inbox" className="btn btn-primary btn-sm">
                                            {""} <i className="mdi mdi-arrow-right ms-1"></i>
                                        </Link> */}
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            
        </React.Fragment>
    );
};

export default InstaProfilePlaceholder;

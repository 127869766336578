import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Card, CardBody, Row } from "reactstrap";
import { clearInstaErrors, getInstaUserInfo } from "src/store/instagram/action";
import InstaProfilePlaceholder from "./InstaProfilePlaceholder";
import { useHistory } from "react-router-dom";

// import users from "../assets/images/small/profile.png";
import small from "../../assets/images/users/rohit-sharma.jpg";

const InstaProfileOverview = () => {
     const { loading, error, userInfo } = useSelector((state: any) => state.userInfo);

     const dispatch = useDispatch();
     const history = useHistory();

     useEffect(() => {
          if (error) {
               dispatch(clearInstaErrors());
          }
     }, [error, dispatch]);

     return (
          <>
               {loading ? (
                    <InstaProfilePlaceholder />
               ) : (
                    <React.Fragment>
                         
                         <Col xl={12}>
                              <Card className="card-h-100">
                                   <CardBody>
                                        <div className="d-flex flex-wrap align-items-center mb-4"></div>
                                        <div className="ms-auto">
                                             <h2 className="mb-1 text-center">{userInfo?.username}</h2>
                                        </div>

                                        <Row className="align-items-center">
                                             <div className="col-sm">
                                                  <div id="" className="position-relative mb-3 text-center">
                                                       <img
                                                            src={`https://app.igmagnify.com/api/v1/insta/img/view?url=${window.btoa(
                                                                 userInfo?.profilePic
                                                            )}&type=jpeg&dlheader=image/jpeg`}
                                                            className="img-thumbnail rounded-circle"
                                                       />
                                                  </div>
                                             </div>

                                             <div className="col-sm align-self-center">
                                                  <div className="mt-4 mt-sm-0">
                                                       <Row className="g-0">
                                                            <Col xs={4}>
                                                                 <div>
                                                                      <p className="mb-2 text-capitalize font-size-18">
                                                                           Posts
                                                                      </p>
                                                                      <h5 className="fw-medium">{userInfo?.posts}</h5>
                                                                 </div>
                                                            </Col>
                                                            <Col xs={4}>
                                                                 <div>
                                                                      <p className="mb-2 text-capitalize font-size-18">
                                                                           Followers
                                                                      </p>
                                                                      <h5 className="fw-medium">
                                                                           {userInfo?.followers}
                                                                      </h5>
                                                                 </div>
                                                            </Col>
                                                            <Col xs={4}>
                                                                 <div>
                                                                      <p className="mb-2 text-capitalize font-size-18">
                                                                           Following
                                                                      </p>
                                                                      <h5 className="fw-medium">
                                                                           {userInfo?.following}
                                                                      </h5>
                                                                 </div>
                                                            </Col>
                                                       </Row>

                                                       <div className="mt-2">
                                                            <h4 className="mb-2 text-capitalize font-size-16">
                                                                 {userInfo?.fullName}
                                                            </h4>
                                                            <p className="biodata">{userInfo?.biography}</p>
                                                            {/* <p className="text-muted">Followed by <span className="text-black">{"siddhant_gupta___"}</span></p>
                                        <Link to="/email-inbox" className="btn btn-primary btn-sm">
                                            View more <i className="mdi mdi-arrow-right ms-1"></i>
                                        </Link> */}
                                                       </div>
                                                  </div>
                                             </div>
                                        </Row>
                                   </CardBody>
                              </Card>
                         </Col>
                        
                    </React.Fragment>
               )}
          </>
     );
};

export default InstaProfileOverview;

import React, { useEffect } from "react";
import { Row, Col, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { clearErrors, register } from "../../store/auth/login/actions";
//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useHistory } from "react-router-dom";

// import images
import logoDark from "../../assets/images/ig-logo-dark.png";
import CarouselPage from "./CarouselPage";
import { LoginTypes } from "src/store/auth/login/actionTypes";

interface LoginProps {
     history: object;
}

const Register = () => {
     const { loading, error, isAuthenticated } = useSelector((state: any) => state.login);

     const dispatch = useDispatch();
     const history = useHistory();

     const validation = useFormik({
          // enableReinitialize : use this flag when initial values needs to be changed
          enableReinitialize: true,

          initialValues: {
               email: "",
               name: "",
               password: "",
          },
          validationSchema: Yup.object({
               email: Yup.string().required("Please Enter Your Email"),
               name: Yup.string().required("Please Enter Your Username"),
               password: Yup.string().required("Please Enter Your Password"),
          }),
          onSubmit: (values) => {
               dispatch(register(values));
          },
     });

     //   const { user, registrationError } = useSelector((state: any) => ({
     //     user: state.register.user,
     //     registrationError: state.register.registrationError,
     //     loading: state.register.loading,
     //   }));

     useEffect(() => {
          if (error) {
               dispatch(clearErrors());
          }

          if (isAuthenticated) {
               history.push("/");
          }
     }, [dispatch, isAuthenticated, history]);

     document.title = "Register - IgMagnify.com";

     return (
          <React.Fragment>
               <div className="auth-page">
                    <Container fluid className="p-0">
                         <Row className="g-0">
                              <Col lg={4} md={5} className="col-xxl-3">
                                   <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                        <div className="w-100">
                                             <div className="d-flex flex-column h-100">
                                                  <div className="mb-4 mb-md-5 text-center">
                                                       <Link to="/dashboard" className="d-block auth-logo">
                                                            <img src={logoDark} alt="" height="" />{" "}
                                                       </Link>
                                                  </div>
                                                  <div className="auth-content my-auto">
                                                       <div className="text-center">
                                                            <h5 className="mb-0">Register Account</h5>
                                                            <p className="text-muted mt-2">
                                                                 Get your free IgMagnify account now.
                                                            </p>
                                                       </div>
                                                       <Form
                                                            className="needs-validation custom-form mt-4 pt-2"
                                                            onSubmit={(e) => {
                                                                 e.preventDefault();
                                                                 validation.handleSubmit();
                                                                 return false;
                                                            }}
                                                       >
                                                            {/* {user && user ? (
                          <Alert color="success">
                            Register User Successfully
                          </Alert>
                        ) : null} */}

                                                            {error && error ? (
                                                                 <Alert color="danger">{error}</Alert>
                                                            ) : null}

                                                            <div className="mb-3">
                                                                 <Label className="form-label">Name</Label>
                                                                 <Input
                                                                      name="name"
                                                                      type="text"
                                                                      placeholder="Enter name"
                                                                      onChange={validation.handleChange}
                                                                      onBlur={validation.handleBlur}
                                                                      value={validation.values.name || ""}
                                                                      invalid={
                                                                           validation.touched.name &&
                                                                           validation.errors.name
                                                                                ? true
                                                                                : false
                                                                      }
                                                                 />
                                                                 {validation.touched.name && validation.errors.name ? (
                                                                      <FormFeedback type="invalid">
                                                                           {validation.errors.name}
                                                                      </FormFeedback>
                                                                 ) : null}
                                                            </div>

                                                            <div className="mb-3">
                                                                 <Label className="form-label">Email</Label>
                                                                 <Input
                                                                      id="email"
                                                                      name="email"
                                                                      className="form-control"
                                                                      placeholder="Enter email"
                                                                      type="email"
                                                                      onChange={validation.handleChange}
                                                                      onBlur={validation.handleBlur}
                                                                      value={validation.values.email || ""}
                                                                      invalid={
                                                                           validation.touched.email &&
                                                                           validation.errors.email
                                                                                ? true
                                                                                : false
                                                                      }
                                                                 />
                                                                 {validation.touched.email &&
                                                                 validation.errors.email ? (
                                                                      <FormFeedback type="invalid">
                                                                           {validation.errors.email}
                                                                      </FormFeedback>
                                                                 ) : null}
                                                            </div>
                                                            <div className="mb-3">
                                                                 <Label className="form-label">Password</Label>
                                                                 <Input
                                                                      name="password"
                                                                      type="password"
                                                                      placeholder="Enter Password"
                                                                      onChange={validation.handleChange}
                                                                      onBlur={validation.handleBlur}
                                                                      value={validation.values.password || ""}
                                                                      invalid={
                                                                           validation.touched.password &&
                                                                           validation.errors.password
                                                                                ? true
                                                                                : false
                                                                      }
                                                                 />
                                                                 {validation.touched.password &&
                                                                 validation.errors.password ? (
                                                                      <FormFeedback type="invalid">
                                                                           {validation.errors.password}
                                                                      </FormFeedback>
                                                                 ) : null}
                                                            </div>

                                                            <div className="mb-4">
                                                                 <p className="mb-0">
                                                                      By registering you agree to the IgMagnify{" "}
                                                                      <Link to="#" className="text-primary">
                                                                           Terms of Use
                                                                      </Link>
                                                                 </p>
                                                            </div>
                                                            <div className="mb-3">
                                                                 {loading ? (
                                                                      <button
                                                                           type="button"
                                                                           className="btn btn-primary w-100 waves-effect waves-light"
                                                                      >
                                                                           <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                                           Loading
                                                                      </button>
                                                                 ) : (
                                                                      <button
                                                                           className="btn btn-primary w-100 waves-effect waves-light"
                                                                           type="submit"
                                                                      >
                                                                           Register
                                                                      </button>
                                                                 )}
                                                            </div>
                                                       </Form>

                                                       <div className="mt-5 text-center">
                                                            <p className="text-muted mb-0">
                                                                 Already have an account ?{" "}
                                                                 <Link to="/login" className="text-primary fw-semibold">
                                                                      {" "}
                                                                      Login{" "}
                                                                 </Link>{" "}
                                                            </p>
                                                       </div>
                                                  </div>
                                                  <div className="mt-4 mt-md-5 text-center">
                                                       <p className="mb-0">
                                                            © {new Date().getFullYear()} IgMagnify . Developed with{" "}
                                                            <i className="mdi mdi-heart text-danger"></i>Team by
                                                            IgMagnify
                                                       </p>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </Col>
                              <CarouselPage />
                         </Row>
                    </Container>
               </div>
          </React.Fragment>
     );
};

export default Register;

import { combineReducers } from "redux"

// Layout
import Layout from "./layout/reducer";

//login
import {userReducer} from "./auth/login/reducer";

//register
// import register from "./auth/register/reducer";

// User Profile 
import profile from "./auth/profile/reducer";

// Forget Password
import forgetPassword from "./auth/forgetpwd/reducer";
//Instagram
import {instaReducer, instaUserFollowersReducer, instaUserFollowingReducer, instaUserInfoReducer, instaUserPostsReducer, instaUserReelsPostsReducer} from "./instagram/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  login: userReducer,
  //register,
  profile,
  forgetPassword,
  instagram: instaReducer,
  userInfo: instaUserInfoReducer,
  userFollowers: instaUserFollowersReducer,
  userFollowing: instaUserFollowingReducer,
  userPosts: instaUserPostsReducer,
  userReels: instaUserReelsPostsReducer,
})

export default rootReducer

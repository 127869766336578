/**
 *  interface for Login Type
*/
export enum LoginTypes {
    LOGIN_REQUEST = '@@login/LOGIN_REQUEST',
    LOGIN_SUCCESS = '@@login/LOGIN_SUCCESS',
    LOGIN_FAIL = '@@login/LOGIN_FAIL',
    CLEAR_ERROR = '@@login/CLEAR_ERROR',

    REGISTER_USER_REQUEST= '@@login/REGISTER_USER_REQUEST',
    REGISTER_USER_FAIL = '@@login/REGISTER_USER_FAIL',
    REGISTER_USER_SUCCESS = '@@login/REGISTER_USER_SUCCESS',

    LOAD_USER_REQUEST = '@@login/LOAD_USER_REQUEST',
    LOAD_USER_SUCCESS = '@@login/LOAD_USER_SUCCESS',
    LOAD_USER_FAIL = '@@login/LOAD_USER_FAIL',

    LOGOUT_SUCCESS= "@@login/LOGOUT_SUCCESS",
    LOGOUT_FAIL = "@@login/LOGOUT_FAIL",
}

import React, { useEffect, useState } from "react";
import {
     Container,
     Card,
     CardBody,
     CardHeader,
     Col,
     Row,
     FormGroup,
     Label,
     Input,
     Button,
     FormFeedback,
     Form,
     Alert,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import { loadUser } from "../../store/auth/login/actions";
import { clearProfileErrors, updatePassword, updateProfile } from "../../store/auth/profile/actions";
import { ProfileTypes } from "src/store/auth/profile/actionTypes";

const UpdatePassword = () => {
     const { error, isUpdated, loading } = useSelector((state: any) => state.profile);

     const dispatch = useDispatch();
     const history = useHistory();

     const [name, setName] = useState("");
     const [email, setEmail] = useState("");

     // Form validation
     const validation = useFormik({
          // enableReinitialize : use this flag when initial values needs to be changed
          enableReinitialize: true,

          initialValues: {
               oldPassword: "",
               newPassword: "",
               confirmPassword: "",
          },
          validationSchema: Yup.object({
               oldPassword: Yup.string().required("Please Enter Your Current Password"),
               newPassword: Yup.string().required("Please Enter Your New Password"),
               confirmPassword: Yup.string().required("Please Enter Your Confirm Password"),
          }),
          onSubmit: (values: any) => {
               const formData = new FormData();

               for (let value in values) {
                    formData.append(value, values[value]);
               }

               dispatch(updatePassword(formData));
          },
     });

     useEffect(() => {
          if (error) {
               
               const interval = setInterval(() => {
                    dispatch(clearProfileErrors());
                  }, 2000);
                
                  return () => clearInterval(interval);
          }

          if (isUpdated) {
               history.push("/user/profile");

               dispatch({
                    type: ProfileTypes.UPDATE_PASSWORD_RESET,
               });
          }
     }, [dispatch, error, alert, isUpdated, history]);

     return (
          <React.Fragment>
               <div className="page-content">
                    <Container fluid>
                         {/* Render Breadcrumb */}
                         <Breadcrumb title="User" breadcrumbItem="Update Password" />

                         <Row>
                              <Col xl={6}>
                                   <Card>
                                        <CardBody>
                                             <Form
                                                  className="needs-validation"
                                                  onSubmit={(e) => {
                                                       e.preventDefault();
                                                       validation.handleSubmit();
                                                       return false;
                                                  }}
                                                  encType="multipart/form-data"
                                             >
                                                  {error ? (
                                                       <Alert color="danger" style={{ marginTop: "13px" }}>
                                                            {error}
                                                       </Alert>
                                                  ) : null}
                                                  {isUpdated ? (
                                                       <Alert color="success" style={{ marginTop: "13px" }}>
                                                            "Password updated successfully!"
                                                       </Alert>
                                                  ) : null}

                                                  <Row>
                                                       <Col md="12">
                                                            <FormGroup className="mb-3">
                                                                 <Label htmlFor="validationCustom01">
                                                                      Current password
                                                                 </Label>
                                                                 <Input
                                                                      name="oldPassword"
                                                                      placeholder="Current password"
                                                                      type="password"
                                                                      className="form-control"
                                                                      id="validationCustom01"
                                                                      onChange={validation.handleChange}
                                                                      onBlur={validation.handleBlur}
                                                                      value={validation.values.oldPassword || ""}
                                                                      invalid={
                                                                           validation.touched.oldPassword &&
                                                                           validation.errors.oldPassword
                                                                                ? true
                                                                                : false
                                                                      }
                                                                 />
                                                                 {validation.touched.oldPassword &&
                                                                 validation.errors.oldPassword ? (
                                                                      <FormFeedback type="invalid">
                                                                           {validation.errors.oldPassword}
                                                                      </FormFeedback>
                                                                 ) : null}
                                                            </FormGroup>
                                                       </Col>
                                                       <Col md="12">
                                                            <FormGroup className="mb-3">
                                                                 <Label htmlFor="validationCustom02">New password</Label>
                                                                 <Input
                                                                      name="newPassword"
                                                                      placeholder="New password"
                                                                      type="text"
                                                                      className="form-control"
                                                                      id="validationCustom02"
                                                                      onChange={validation.handleChange}
                                                                      onBlur={validation.handleBlur}
                                                                      value={validation.values.newPassword || ""}
                                                                      invalid={
                                                                           validation.touched.newPassword &&
                                                                           validation.errors.newPassword
                                                                                ? true
                                                                                : false
                                                                      }
                                                                 />
                                                                 {validation.touched.newPassword &&
                                                                 validation.errors.newPassword ? (
                                                                      <FormFeedback type="invalid">
                                                                           {validation.errors.newPassword}
                                                                      </FormFeedback>
                                                                 ) : null}
                                                            </FormGroup>
                                                       </Col>
                                                       <Col md="12">
                                                            <FormGroup className="mb-3">
                                                                 <Label htmlFor="validationCustom03">Confirm password</Label>
                                                                 <Input
                                                                      name="confirmPassword"
                                                                      placeholder="Confirm Password"
                                                                      type="password"
                                                                      className="form-control"
                                                                      id="validationCustom03"
                                                                      onChange={validation.handleChange}
                                                                      onBlur={validation.handleBlur}
                                                                      value={validation.values.confirmPassword || ""}
                                                                      invalid={
                                                                           validation.touched.confirmPassword &&
                                                                           validation.errors.confirmPassword
                                                                                ? true
                                                                                : false
                                                                      }
                                                                 />
                                                                 {validation.touched.confirmPassword &&
                                                                 validation.errors.confirmPassword ? (
                                                                      <FormFeedback type="invalid">
                                                                           {validation.errors.confirmPassword}
                                                                      </FormFeedback>
                                                                 ) : null}
                                                            </FormGroup>
                                                       </Col>
                                                  </Row>

                                                  <Button color="primary" type="submit">
                                                       Submit form
                                                  </Button>
                                             </Form>
                                        </CardBody>
                                   </Card>
                              </Col>
                         </Row>
                    </Container>
               </div>
          </React.Fragment>
     );
};

export default UpdatePassword;

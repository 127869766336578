import React, { useEffect } from "react";

import { Row, Col, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link, useHistory } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { login, clearErrors } from "../../store/auth/login/actions";

// import images
import logoDark from "../../assets/images/ig-logo-dark.png";

//Import config
import config from "../../config";
import CarouselPage from "../Authentication/CarouselPage";

interface LoginProps {
     history: object;
}

const Login = ({ history }: LoginProps) => {
     const { error, loading, isAuthenticated } = useSelector((state: any) => state.login);

     const dispatch = useDispatch();
     const navHistory = useHistory();

     const validation = useFormik({
          // enableReinitialize : use this flag when initial values needs to be changed
          enableReinitialize: true,

          initialValues: {
               email: "",
               password: "",
          },
          validationSchema: Yup.object({
               email: Yup.string().required("Please Enter Your Email"),
               password: Yup.string().required("Please Enter Your Password"),
          }),
          onSubmit: (values) => {
               dispatch(login(values, history));
          },
     });

     useEffect(() => {
          // if(error){
          //   dispatch(clearErrors());
          // }

          if (isAuthenticated) {
               navHistory.push("/dashboard");
          }
     }, [dispatch, error, isAuthenticated, navHistory]);

     document.title = "Login - IgMagnify.com";

     return (
          <React.Fragment>
               <div className="auth-page">
                    <Container fluid className="p-0">
                         <Row className="g-0">
                              <Col lg={4} md={5} className="col-xxl-3">
                                   <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                        <div className="w-100">
                                             <div className="d-flex flex-column h-100">
                                                  <div className="mb-4 mb-md-5 text-center">
                                                       <Link to="/dashboard" className="d-block auth-logo">
                                                            <img src={logoDark} alt="IgMagnify" height="" />{" "}
                                                            {/* <span className="logo-txt">Minia</span> */}
                                                       </Link>
                                                  </div>
                                                  <div className="auth-content my-auto">
                                                       <div className="text-center">
                                                            <h5 className="mb-0">Welcome Back !</h5>
                                                            <p className="text-muted mt-2">
                                                                 Sign in to continue to Igmagnify.
                                                            </p>
                                                       </div>
                                                       <Form
                                                            className="custom-form mt-4 pt-2"
                                                            onSubmit={(e) => {
                                                                 e.preventDefault();
                                                                 validation.handleSubmit();
                                                                 return false;
                                                            }}
                                                       >
                                                            {error ? <Alert color="danger">{error}</Alert> : null}
                                                            <div className="mb-3">
                                                                 <Label className="form-label">Email</Label>
                                                                 <Input
                                                                      name="email"
                                                                      className="form-control"
                                                                      placeholder="Enter email"
                                                                      type="email"
                                                                      onChange={validation.handleChange}
                                                                      onBlur={validation.handleBlur}
                                                                      value={validation.values.email || ""}
                                                                      invalid={
                                                                           validation.touched.email &&
                                                                           validation.errors.email
                                                                                ? true
                                                                                : false
                                                                      }
                                                                 />
                                                                 {validation.touched.email &&
                                                                 validation.errors.email ? (
                                                                      <FormFeedback type="invalid">
                                                                           {validation.errors.email}
                                                                      </FormFeedback>
                                                                 ) : null}
                                                            </div>

                                                            <div className="mb-3">
                                                                 <Label className="form-label">Password</Label>
                                                                 <Input
                                                                      name="password"
                                                                      value={validation.values.password || ""}
                                                                      type="password"
                                                                      placeholder="Enter Password"
                                                                      onChange={validation.handleChange}
                                                                      onBlur={validation.handleBlur}
                                                                      invalid={
                                                                           validation.touched.password &&
                                                                           validation.errors.password
                                                                                ? true
                                                                                : false
                                                                      }
                                                                 />
                                                                 {validation.touched.password &&
                                                                 validation.errors.password ? (
                                                                      <FormFeedback type="invalid">
                                                                           {validation.errors.password}
                                                                      </FormFeedback>
                                                                 ) : null}
                                                            </div>

                                                            <div className="row mb-4">
                                                                 <div className="col">
                                                                      <div className="form-check">
                                                                           <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id="remember-check"
                                                                           />
                                                                           <label
                                                                                className="form-check-label"
                                                                                htmlFor="remember-check"
                                                                           >
                                                                                Remember me
                                                                           </label>
                                                                      </div>

                                                                      <div className="mt-3 d-grid">
                                                                           {loading ? (
                                                                                <button
                                                                                     type="button"
                                                                                     className="btn btn-primary waves-effect waves-light"
                                                                                >
                                                                                     <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                                                     Loading
                                                                                </button>
                                                                           ) : (
                                                                                <button
                                                                                     className="btn btn-primary btn-block"
                                                                                     type="submit"
                                                                                     // disabled={loading? true : false}
                                                                                >
                                                                                     Log In
                                                                                </button>
                                                                           )}
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </Form>

                                                       <div className="mt-5 text-center">
                                                            <p className="text-muted mb-0">
                                                                 Don't have an account ?{" "}
                                                                 <Link
                                                                      to="/register"
                                                                      className="text-primary fw-semibold"
                                                                 >
                                                                      {" "}
                                                                      Signup now{" "}
                                                                 </Link>{" "}
                                                            </p>
                                                            <p className="text-muted mb-0">
                                                                 Need to find password ?{" "}
                                                                 <Link
                                                                      to="/password/forgot"
                                                                      className="text-primary fw-semibold"
                                                                 >
                                                                      {" "}
                                                                      Reset Password{" "}
                                                                 </Link>{" "}
                                                            </p>
                                                       </div>
                                                  </div>
                                                  <div className="mt-4 mt-md-5 text-center">
                                                       <p className="mb-0">
                                                            © {new Date().getFullYear()} IgMagnify . Developed with{" "}
                                                            <i className="mdi mdi-heart text-danger"></i> by Team
                                                            IgMagnify
                                                       </p>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </Col>
                              <CarouselPage />
                         </Row>
                    </Container>
               </div>
          </React.Fragment>
     );
};

export default withRouter(Login);

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row, TabContent, TabPane } from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
//import images
import small from "../../assets/images/small/img-2.jpg";
import small2 from "../../assets/images/small/img-6.jpg";
import small3 from "../../assets/images/small/img-1.jpg";
import users from "../../assets/images/users/avatar-1.jpg";

const Reels = () => {
     return (
          <React.Fragment>
               <div className="page-content">
                    <Container fluid>
                         {/* Render Breadcrumbs */}
                         <Breadcrumbs title="Insta" breadcrumbItem="Reels" />

                         <Col xl={12} lg={10}>
                              <Card>
                                   <TabContent>
                                        <TabPane>
                                             <div>
                                                  <Row className="justify-content-center">
                                                       <Col xl={6}>
                                                            <div className="m-t-150">
                                                                 <div className="p-4">
                                                                      <div className="embed-responsive embed-responsive-16by9 ratio ratio-9x16">
                                                                           <iframe
                                                                                title="test"
                                                                                className="embed-responsive-item"
                                                                                src="https://www.youtube.com/embed/1y_kfWUCFDQ"
                                                                           />
                                                                      </div>
                                                                 </div>

                                                                 <hr className="my-4" />

                                                                 <div>
                                                                      <div className="embed-responsive embed-responsive-16by9 ratio ratio-9x16">
                                                                           <iframe
                                                                                title="test"
                                                                                className="embed-responsive-item"
                                                                                src="https://www.youtube.com/embed/1y_kfWUCFDQ"
                                                                           />
                                                                      </div>
                                                                 </div>

                                                                 <hr className="my-4" />
                                                                 <div>
                                                                      <div className="embed-responsive embed-responsive-16by9 ratio ratio-9x16">
                                                                           <iframe
                                                                                title="test"
                                                                                className="embed-responsive-item"
                                                                                src="https://www.youtube.com/embed/1y_kfWUCFDQ"
                                                                           />
                                                                      </div>
                                                                 </div>
                                                                 <hr className="my-4" />
                                                                 <div className="text-center">
                                                                      <ul className="pagination justify-content-center pagination-rounded">
                                                                           <li className="page-item disabled">
                                                                                <Link to="#" className="page-link">
                                                                                     <i className="mdi mdi-chevron-left"></i>
                                                                                </Link>
                                                                           </li>
                                                                           <li className="page-item">
                                                                                <Link to="#" className="page-link">
                                                                                     1
                                                                                </Link>
                                                                           </li>
                                                                           <li className="page-item active">
                                                                                <Link to="#" className="page-link">
                                                                                     2
                                                                                </Link>
                                                                           </li>
                                                                           <li className="page-item">
                                                                                <Link to="#" className="page-link">
                                                                                     3
                                                                                </Link>
                                                                           </li>
                                                                           <li className="page-item">
                                                                                <Link to="#" className="page-link">
                                                                                     ...
                                                                                </Link>
                                                                           </li>
                                                                           <li className="page-item">
                                                                                <Link to="#" className="page-link">
                                                                                     10
                                                                                </Link>
                                                                           </li>
                                                                           <li className="page-item">
                                                                                <Link to="#" className="page-link">
                                                                                     <i className="mdi mdi-chevron-right"></i>
                                                                                </Link>
                                                                           </li>
                                                                      </ul>
                                                                 </div>
                                                            </div>
                                                       </Col>
                                                  </Row>
                                             </div>
                                        </TabPane>

                                        {/* <TabPane tabId="2">
                            <div>
                                <Row className="justify-content-center">
                                    <Col xl={8}>
                                        <h5>Archive</h5>

                                        <div className="mt-5">
                                            <div className="d-flex flex-wrap">
                                                <div className="me-2">
                                                    <h4>2020</h4>
                                                </div>
                                                <div className="ms-auto">
                                                    <span className="badge badge-soft-success rounded-pill float-end ms-1 font-size-12">
                                                        03
                                                    </span>
                                                </div>
                                            </div>
                                            <hr className="mt-2" />

                                            <div className="list-group list-group-flush">
                                                <Link
                                                    to="/blog-details"
                                                    className="list-group-item text-muted"
                                                >
                                                    <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                    Beautiful Day with Friends
                                                </Link>

                                                <Link
                                                    to="/blog-details"
                                                    className="list-group-item text-muted"
                                                >
                                                    <i className="mdi mdi-circle-medium me-1"></i> Drawing
                                                    a sketch
                                                </Link>

                                                <Link
                                                    to="/blog-details"
                                                    className="list-group-item text-muted"
                                                >
                                                    <i className="mdi mdi-circle-medium me-1"></i> Project
                                                    discussion with team
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="mt-5">
                                            <div className="d-flex flex-wrap">
                                                <div className="me-2">
                                                    <h4>2019</h4>
                                                </div>
                                                <div className="ms-auto">
                                                    <span className="badge badge-soft-success badge-pill float-right ms-1 font-size-12">
                                                        06
                                                    </span>
                                                </div>
                                            </div>
                                            <hr className="mt-2" />

                                            <div className="list-group list-group-flush">
                                                <Link
                                                    to="/blog-details"
                                                    className="list-group-item text-muted"
                                                >
                                                    <i className="mdi mdi-circle-medium me-1"></i> Coffee
                                                    with Friends
                                                </Link>

                                                <Link
                                                    to="/blog-details"
                                                    className="list-group-item text-muted"
                                                >
                                                    <i className="mdi mdi-circle-medium me-1"></i> Neque
                                                    porro quisquam est
                                                </Link>

                                                <Link
                                                    to="/blog-details"
                                                    className="list-group-item text-muted"
                                                >
                                                    <i className="mdi mdi-circle-medium me-1"></i> Quis
                                                    autem vel eum iure
                                                </Link>

                                                <Link
                                                    to="/blog-details"
                                                    className="list-group-item text-muted"
                                                >
                                                    <i className="mdi mdi-circle-medium me-1"></i> Cras mi
                                                    eu turpis
                                                </Link>

                                                <Link
                                                    to="/blog-details"
                                                    className="list-group-item text-muted"
                                                >
                                                    <i className="mdi mdi-circle-medium me-1"></i> Drawing
                                                    a sketch
                                                </Link>

                                                <Link
                                                    to="/blog-details"
                                                    className="list-group-item text-muted"
                                                >
                                                    <i className="mdi mdi-circle-medium me-1"></i> Project
                                                    discussion with team
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="mt-5">
                                            <div className="d-flex flex-wrap">
                                                <div className="me-2">
                                                    <h4>2018</h4>
                                                </div>
                                                <div className="ms-auto">
                                                    <span className="badge badge-soft-success rounded-pill float-end ms-1 font-size-12">
                                                        03
                                                    </span>
                                                </div>
                                            </div>
                                            <hr className="mt-2" />

                                            <div className="list-group list-group-flush">
                                                <Link
                                                    to="/blog-details"
                                                    className="list-group-item text-muted"
                                                >
                                                    <i className="mdi mdi-circle-medium me-1"></i>{" "}
                                                    Beautiful Day with Friends
                                                </Link>

                                                <Link
                                                    to="/blog-details"
                                                    className="list-group-item text-muted"
                                                >
                                                    <i className="mdi mdi-circle-medium me-1"></i> Drawing
                                                    a sketch
                                                </Link>

                                                <Link
                                                    to="/blog-details"
                                                    className="list-group-item text-muted"
                                                >
                                                    <i className="mdi mdi-circle-medium me-1"></i> Project
                                                    discussion with team
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </TabPane> */}
                                   </TabContent>
                              </Card>
                         </Col>
                    </Container>
               </div>
          </React.Fragment>
     );
};

export default Reels;

import React from 'react';
import { Redirect } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard";

//Authentication pages
import Login from "src/pages/Authentication/Login";
// import Logout from "src/pages/Authentication/Logout";
// import Register from "src/pages/Authentication/Register";
import ForgetPassword from "src/pages/Authentication/ForgetPassword";
import ResetPassword from 'src/pages/Authentication/ResetPassword';
import UserProfile from 'src/pages/User/UserProfile';
import UpdateProfile from 'src/pages/User/UpdateProfile';
import UpdatePassword from 'src/pages/User/UpdatePassword';
import Test from 'src/pages/Test';
// import Media from 'src/pages/Insta/Media';
// import Posts from 'src/components/Instagram/Post';
import Reels from 'src/pages/Insta/Reels';
import Followers from 'src/pages/Insta/Followers';
import Following from 'src/pages/Insta/Following';
import Register from 'src/pages/Authentication/Register';
import InstaProfile from 'src/pages/Insta/Profile';
import InstaSearch from 'src/pages/Insta';

// import userProfile from "src/pages/Authentication/user-profile";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  //User Profile
  { path: "/user/profile", component: UserProfile },
  { path: "/user/update/profile", component: UpdateProfile },
  { path: "/user/update/password", component: UpdatePassword },

  //dashboard
  { path: "/dashboard", component: Dashboard },

  //Insta
  {path: "/insta/search", component: InstaSearch},
  {path: "/insta/profile/:username", component: InstaProfile},
  { path: "/insta/followers", component: Followers },
  { path: "/insta/following", component: Following },
  // { path: "/insta/media", component: Media },
  // { path: "/insta/posts", component: Posts },
  { path: "/insta/reels", component: Reels },
  

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: Login },
  // { path: "/logout", component: Logout },
  { path: "/register", component: Register },
  { path: "/password/forgot", component: ForgetPassword },
  { path: "/password/reset/:token", component: ResetPassword },
  { path: "/test", component: Test },
];

export { userRoutes, authRoutes };

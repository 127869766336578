import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

const SearchFaq = () => {
     return (
          <Row className="mt-5">
               <Col xl={4} sm={6}>
                    <Card>
                         <CardBody className="overflow-hidden position-relative">
                              <div>
                                   <i className="bx bx-help-circle widget-box-1-icon text-primary"></i>
                              </div>
                              <div className="faq-count">
                                   <h5 className="text-primary">01.</h5>
                              </div>
                              <h5 className="mt-3">
                                   Does IG Magnify offer safe and secure
                                   services?
                              </h5>
                              <p className="text-muted mt-3 mb-0">
                                   We provide complete security to the data of
                                   our customers. IG magnify does not refer to
                                   any private information secured by the
                                   website or any third party.
                              </p>
                         </CardBody>
                    </Card>
               </Col>
               <Col xl={4} sm={6}>
                    <Card>
                         <CardBody className="overflow-hidden position-relative">
                              <div>
                                   <i className="bx bx-help-circle widget-box-1-icon text-primary"></i>
                              </div>
                              <div className="faq-count">
                                   <h5 className="text-primary">02.</h5>
                              </div>
                              <h5 className="mt-3">
                                   What about Customer Support?
                              </h5>
                              <p className="text-muted mt-3 mb-0">
                                   IG magnify offers 24/7 customer support. Our
                                   customers are free to ask their queries
                                   related to services, payment options, and
                                   similar questions. All you need to do is to
                                   update your query in a proper format to our
                                   customer support.
                              </p>
                         </CardBody>
                    </Card>
               </Col>

               <Col xl={4} sm={6}>
                    <Card>
                         <CardBody className="overflow-hidden position-relative">
                              <div>
                                   <i className="bx bx-help-circle widget-box-1-icon text-primary"></i>
                              </div>
                              <div className="faq-count">
                                   <h5 className="text-primary">03.</h5>
                              </div>
                              <h5 className="mt-3">
                                   Can the person know if I view its profile?
                              </h5>
                              <p className="text-muted mt-3 mb-0">
                                   No, IG magnify allows you to watch Instagram
                                   profiles anonymously.
                              </p>
                         </CardBody>
                    </Card>
               </Col>

               <Col xl={4} sm={6}>
                    <Card>
                         <CardBody className="overflow-hidden position-relative">
                              <div>
                                   <i className="bx bx-help-circle widget-box-1-icon text-primary"></i>
                              </div>
                              <div className="faq-count">
                                   <h5 className="text-primary">04.</h5>
                              </div>
                              <h5 className="mt-3">
                                   What kind of devices does IG Magnify Support?
                              </h5>
                              <p className="text-muted mt-3 mb-0">
                                   If magnify supports all kinds of devices.
                              </p>
                         </CardBody>
                    </Card>
               </Col>

               <Col xl={4} sm={6}>
                    <Card>
                         <CardBody className="overflow-hidden position-relative">
                              <div>
                                   <i className="bx bx-help-circle widget-box-1-icon text-primary"></i>
                              </div>
                              <div className="faq-count">
                                   <h5 className="text-primary">05.</h5>
                              </div>
                              <h5 className="mt-3">
                                   How many times can I view the Profile?
                              </h5>
                              <p className="text-muted mt-3 mb-0">
                                   You can view the profile as many times as you
                                   want, little it depends on the package you
                                   are availing.
                              </p>
                         </CardBody>{" "}
                    </Card>
               </Col>

               <Col xl={4} sm={6}>
                    <Card>
                         <CardBody className="overflow-hidden position-relative">
                              <div>
                                   <i className="bx bx-help-circle widget-box-1-icon text-primary"></i>
                              </div>
                              <div className="faq-count">
                                   <h5 className="text-primary">06.</h5>
                              </div>
                              <h5 className="mt-3">
                                   Is it important to get registered on the
                                   platform?
                              </h5>
                              <p className="text-muted mt-3 mb-0">
                                   Yes, you need to register on the platform to
                                   view Instagram profiles privately.
                              </p>
                         </CardBody>
                    </Card>
               </Col>
          </Row>
     );
};

export default SearchFaq;

import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, FormFeedback, Input, Button } from "reactstrap";

//Import Icons
import Icon from "@ailibs/feather-react-ts";
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
import { clearInstaErrors, getInstaUserID, getInstaUserInfo, getUserPosts } from "src/store/instagram/action";
import { InstagramTypes } from "src/store/instagram/actionType";
import UiLoader from "src/components/UiLoader";
import SearchFaq from "./SearchFaq";

const Search = () => {
     const { loading, error, userID } = useSelector((state: any) => state.instagram);
     const { loading: loadingUserInfo, error: errorUserInfo, userInfo } = useSelector((state: any) => state.userInfo);

     const [errMsg, setErrMsg] = useState("");

     const dispatch = useDispatch();
     const history = useHistory();

     // Form validation
     const validation = useFormik({
          // enableReinitialize : use this flag when initial values needs to be changed
          enableReinitialize: true,

          initialValues: {
               searchText: "",
          },
          validationSchema: Yup.object({
               searchText: Yup.string().required("Please Enter Insta Username"),
          }),
          onSubmit: (values: any) => {
               //alert(values);
               dispatch(getInstaUserID(values, history));
          },
     });

     useEffect(() => {
          // dispatch({ type: InstagramTypes.INSTA_USER_INFO_RESET });
          // dispatch({ type: InstagramTypes.INSTA_POSTS_RESET });
          // dispatch({ type: InstagramTypes.INSTA_REELS_POSTS_RESET });
          
          if (error) {
               setErrMsg(error);
               dispatch(clearInstaErrors());
          }
          if (userID) {
               console.log(userID.id);
               
               dispatch(getInstaUserInfo(userID.id));

               if(userInfo){
                    setErrMsg("");
                    userID.id ? history.push(`/insta/profile/${userID.id}`) : setErrMsg(userID.message);
               }
          }
     }, [error, userID, dispatch]);

     return (
          <Row>
               <Col xl={12}>
                    <Card className="card-h-100">
                         <CardBody>
                              <Row className="justify-content-center mt-3">
                              <div className="text-center col-sm-6">
                              <h2>Find Instagram Profile and Search</h2>
                                             <p className="text-muted">
                                                  If several languages coalesce, the grammar of the resulting language
                                                  is more simple and regular than that of the individual
                                             </p>
                              </div>
                                   <div className="col-xl-8 col-lg-12">
                                        <div className="text-center">
                                        {/* <UiLoader loadingStatus={true} /> */}
                                             {loading || loadingUserInfo ? (
                                                  <UiLoader loadingStatus={loading}/>
                                             ) : (
                                                  <Row className="justify-content-center">
                                                       <div className="col-xl-10">
                                                            <h5 className="text-danger">{errMsg ? errMsg : null}</h5>
                                                            <form
                                                                 onSubmit={(e) => {
                                                                      e.preventDefault();
                                                                      validation.handleSubmit();
                                                                      return false;
                                                                 }}
                                                                 className="bg-soft-light-search  mt-4"
                                                            >
                                                                 <div className="position-relative">
                                                                      <Input
                                                                           name="searchText"
                                                                           type="text"
                                                                           className="form-control border-search bg-soft-light-search"
                                                                           placeholder="Search..."
                                                                           id="validationCustom01"
                                                                           onChange={validation.handleChange}
                                                                           onBlur={validation.handleBlur}
                                                                           value={validation.values.searchText || ""}
                                                                           invalid={
                                                                                validation.touched.searchText &&
                                                                                validation.errors.searchText
                                                                                     ? true
                                                                                     : false
                                                                           }
                                                                      />
                                                                      {validation.touched.searchText &&
                                                                      validation.errors.searchText ? (
                                                                           <FormFeedback type="invalid">
                                                                                {validation.errors.searchText}
                                                                           </FormFeedback>
                                                                      ) : null}
                                                                      <div className="mt-4 text-center">
                                                                           <Button
                                                                                type="submit"
                                                                                color="primary"
                                                                                // onClick={() => addMessage(currentRoomId, currentUser.name)}
                                                                                className="btn btn-primary chat-send w-md"
                                                                           >
                                                                                <span className=" d-sm-inline-block me-2">
                                                                                     Search
                                                                                </span>{" "}
                                                                                <i className="bx bx-search-alt align-middle"></i>
                                                                           </Button>
                                                                      </div>
                                                                 </div>
                                                            </form>
                                                       </div>
                                                  </Row>
                                             )}
                                        </div>
                                   </div>
                              </Row>
                              <SearchFaq/>
                         </CardBody>
                    </Card>
               </Col>
          </Row>
     );
};

export default Search;

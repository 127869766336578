import React from "react";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

const DownloadVideo = ({ download }: any) => {
     // console.log(download);

     const randomFixedInteger = function (length: number) {
          return Math.floor(
               Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1)
          );
     };

     return (
          <>
               <button
                    type="button"
                    color="light"
                    className="btn btn-outline-light waves-effect btn-label waves-light float-end"
               >
                    <a
                         href={`https://app.igmagnify.com/api/v1/insta/video/download?url=${window.btoa(
                              download
                         )}&type=mp4&dlheader=video/mp4`}
                         rel="noreferrer noopener"
                         target="_blank"
                    >
                         Download <i className="bx bx-download label-icon font-size-16 align-middle"></i>
                    </a>
               </button>
               {/* <UncontrolledDropdown className="float-end">
                    <DropdownToggle
                         type="button"
                         color="primary"
                         className="btn btn-primary waves-effect btn-label waves-light float-end"
                    >
                         Download <i className="bx bx-download label-icon"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                         <DropdownItem to="#">Small (640 x 1137)</DropdownItem>
                         <DropdownItem to="#">Medium (750 x 1333)</DropdownItem>
                         <DropdownItem to="#">Large (1080 x 1920)</DropdownItem>
                    </DropdownMenu>
               </UncontrolledDropdown> */}
          </>
     );
};

export default DownloadVideo;

import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

const DownloadImage = ({ download }: any) => {
      //console.log(download);

     return (
          <>
               <UncontrolledDropdown className="float-end">
                    <DropdownToggle
                         type="button"
                         color="light"
                         className="btn btn-outline-light waves-effect btn-label waves-light float-end"
                    >                   
                         Download <i className="bx bx-download label-icon font-size-16 align-middle"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                    {download?.map((curElem: any, idx: number) => (
                              <DropdownItem key={idx}>
                                   <a href={`https://app.igmagnify.com/api/v1/insta/img/download?url=${window.btoa(curElem?.src)}&type=jpeg&dlheader=image/jpeg`} rel="noreferrer noopener" target="_blank">Version: {idx + 1} ({curElem?.config_width} x {curElem?.config_height})</a>
                              </DropdownItem>
                         ))}
                    </DropdownMenu>
               </UncontrolledDropdown>
          </>
     );
};

export default DownloadImage;

import React from "react";
import { Link } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import axios from "axios";

const DownloadReel = ({ download }: any) => {
     // console.log(download);

     return (
          <>
               <UncontrolledDropdown className="float-end">
                    <DropdownToggle
                         type="button"
                         color="light"
                         className="btn btn-outline-light waves-effect btn-label waves-light float-end"
                    >
                         Download <i className="bx bx-download label-icon font-size-16 align-middle"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                         {download?.map((curElem: any, idx: number) => (
                              <DropdownItem key={idx}>
                                   <a
                                        href={`https://app.igmagnify.com/api/v1/insta/reel/download?url=${window.btoa(
                                             curElem?.url
                                        )}&type=mp4&dlheader=video/mp4`}
                                        rel="noreferrer noopener"
                                        target="_blank"
                                   >
                                        Version: {curElem?.type} ({curElem?.width} x {curElem?.height})
                                   </a>
                              </DropdownItem>
                         ))}
                    </DropdownMenu>
               </UncontrolledDropdown>
          </>
     );
};

export default DownloadReel;

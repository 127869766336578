import React, { useEffect } from "react";

import { Switch, BrowserRouter as Router } from "react-router-dom";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

//redux
import { useSelector } from "react-redux";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/index";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

//api config
// import config from "./config";

// Activating fake backend

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(config["firebase"]);

import store from "./store";
import { loadUser } from "./store/actions";

const App = () => {
     const { layoutType } = useSelector((state: any) => ({
          layoutType: state.Layout.layoutType,
     }));

     function getLayout() {
          let layoutCls: Object = VerticalLayout;
          switch (layoutType) {
               case "horizontal":
                    layoutCls = HorizontalLayout;
                    break;
               default:
                    layoutCls = VerticalLayout;
                    break;
          }
          return layoutCls;
     }

     useEffect(() => {
          store.dispatch(loadUser());
     },[store]);

     const Layout = getLayout();
     return (
          <React.Fragment>
               <Router basename="/">
                    <Switch>
                         {authRoutes.map((route, idx) => (
                              <Authmiddleware
                                   path={route.path}
                                   layout={NonAuthLayout}
                                   component={route.component}
                                   key={idx}
                                   isAuthProtected={false}
                                   exact
                              />
                         ))}

                         {userRoutes.map((route: any, idx: number) => (
                              <Authmiddleware
                                   path={route.path}
                                   layout={Layout}
                                   component={route.component}
                                   key={idx}
                                   isAuthProtected={true}
                                   exact
                              />
                         ))}
                    </Switch>
               </Router>
          </React.Fragment>
     );
};

export default App;

/**
 *  interface for Login Type
*/
export enum ForgetPwdTypes {
    FORGOT_PASSWORD_REQUEST = '@@forget/FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS = '@@forget/FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAIL = '@@forget/FORGOT_PASSWORD_FAIL',

    RESET_PASSWORD_REQUEST = "@@forget/RESET_PASSWORD_REQUEST",
    RESET_PASSWORD_SUCCESS= "@@forget/RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAIL = "@@forget/RESET_PASSWORD_FAIL",

    CLEAR_ERROR = "@@forget/CLEAR_ERROR",
}
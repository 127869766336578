import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle, Container, Table } from "reactstrap";
import { Link } from "react-router-dom";
import users from "../../../assets/images/users/avatar-1.jpg";
import { useSelector } from "react-redux";

const InstaFollowing = () => {
     const { loading, error, instaFollowing } = useSelector((state: any) => state.userFollowing);

     return (
          <React.Fragment>
               {loading ? null : (
                    <div className="table-responsive">
                         <Table className="table align-middle table-nowrap" size="sm">
                              <thead>
                                   <tr>
                                        <th>Profile Pic</th>
                                        <th>Name</th>
                                        <th>Username</th>
                                   </tr>
                              </thead>
                              <tbody key={""}>
                                   {instaFollowing?.followersList?.map((user: any, idx: number) => (
                                        <tr key={idx}>
                                             <td>
                                                  <img
                                                       src={`https://cdn.privatephotoviewer.com/img.php?img=${encodeURIComponent(
                                                            user?.profile_pic_url
                                                       )}`}
                                                       className="rounded-circle header-profile-user"
                                                       alt=""
                                                  />
                                             </td>
                                             <td style={{ width: "" }}>
                                                  
                                                       
                                                            {user?.full_name}
                                                       
                                                  
                                             </td>
                                             <td style={{ width: "" }}>
                                                  
                                                       
                                                            {user?.username}
                                                       
                                                  
                                             </td>
                                        </tr>
                                   ))}
                              </tbody>
                         </Table>
                    </div>
               )}
          </React.Fragment>
     );
};

export default InstaFollowing;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { Link } from "react-router-dom";

// users
import dummyUser from "../../../assets/images/users/avatar-1.jpg";

//redux
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../store/auth/login/actions";

const ProfileMenu = (props: any) => {
     const dispatch = useDispatch();

     const { name, avatar } = useSelector((state: any) => state.login.user);

     // Declare a new state variable, which we'll call "menu"
     const [menu, setMenu] = useState<boolean>(false);

     const [username, setusername] = useState("Admin");

     const handleLogout = () => {
          dispatch(logout());
          alert("Logout Successfully");
     };

     // useEffect(() => {
     //   const getAuthUser = localStorage.getItem("authUser");
     //   if (getAuthUser) {
     //     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
     //       const obj = JSON.parse(getAuthUser);
     //       setusername(obj.displayName);
     //     } else if (
     //       process.env.REACT_APP_DEFAULTAUTH === "fake" ||
     //       process.env.REACT_APP_DEFAULTAUTH === "jwt"
     //     ) {
     //       const obj = JSON.parse(getAuthUser);
     //       setusername(obj.username);
     //     }
     //   }
     // }, [success]);

     return (
          <React.Fragment>
               <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
                    <DropdownToggle
                         className="btn header-item bg-soft-light border-start border-end"
                         id="page-header-user-dropdown"
                         tag="button"
                    >
                         <img className="rounded-circle header-profile-user" src={avatar.url} alt="Header Avatar" />
                         <span className="d-none d-xl-inline-block ms-2 me-1">{name}</span>
                         <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                         <Link to="/user/profile">
                              <DropdownItem>
                                   {" "}
                                   <i className="bx bx-user font-size-16 align-middle me-1" />
                                   {props.t("Profile")}
                              </DropdownItem>
                         </Link>
                         <DropdownItem tag="a" href="/profile">
                              <span className="badge bg-success float-end">11</span>
                              <i className="bx bx-wrench font-size-16 align-middle me-1" />
                              {props.t("Settings")}
                         </DropdownItem>
                         <DropdownItem tag="a" href="/page-lock-screen">
                              <i className="bx bx-lock-open font-size-16 align-middle me-1" />
                              {props.t("Lock screen")}
                         </DropdownItem>
                         <div className="dropdown-divider" />
                         <Link to="" onClick={handleLogout} className="dropdown-item">
                              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                              <span>{props.t("Logout")}</span>
                         </Link>
                    </DropdownMenu>
               </Dropdown>
          </React.Fragment>
     );
};

ProfileMenu.propTypes = {
     success: PropTypes.any,
     t: PropTypes.any,
};
export default withTranslation()(ProfileMenu);

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardHeader, Col, Container, Row, TabContent, TabPane } from "reactstrap";

import Breadcrumbs from "../../Common/Breadcrumb";
//import images
import small from "../../assets/images/small/img-2.jpg";
import small2 from "../../assets/images/small/img-6.jpg";
import small3 from "../../assets/images/small/img-1.jpg";
import users from "../../assets/images/users/avatar-1.jpg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearInstaErrors, getUserPosts } from "src/store/instagram/action";
import { InstagramTypes } from "src/store/instagram/actionType";
import Timeline from "./Timeline";

const ReelsPosts = () => {
     const { loading, error, instaReelsPosts } = useSelector((state: any) => state.userReels);

     const dispatch = useDispatch();
     const history = useHistory();

     useEffect(() => {
          if (error) {
               dispatch(clearInstaErrors());
          }
     }, [dispatch, error]);

     return (
          <React.Fragment>
               {instaReelsPosts ?  (
                    <div>
                         <Row className="justify-content-center">
                              <Col xl={6}>
                                   {instaReelsPosts?.reelsList?.map((curPost: any, idx: number) => (
                                        <div key={idx}>
                                             <Timeline Post={curPost} />
                                        </div>
                                   ))}
                              </Col>
                         </Row>
                    </div>
               ) : (
                    null
               )}
          </React.Fragment>
     );
};

export default ReelsPosts;

import { LoginTypes } from "./actionTypes";
import axios from "axios";

//Login
export const login = (user: any, history: any) => async (dispatch: any) => {
     try {
          dispatch({ type: LoginTypes.LOGIN_REQUEST });

          const config = { headers: { "Content-Type": "application/json" } };

          const { data } = await axios.post(`/api/v1/login`, { email: user.email, password: user.password }, config);

          dispatch({ type: LoginTypes.LOGIN_SUCCESS, payload: data.user });
     } catch (error: any) {
          dispatch({ type: LoginTypes.LOGIN_FAIL, payload: error.response.data.message });
     }
};

//Register
export const register = (userData: any) => async (dispatch: any) => {
     try {
          dispatch({ type: LoginTypes.REGISTER_USER_REQUEST });

          const config = { headers: { "Content-Type": "application/json" } };

          const { data } = await axios.post(`/api/v1/register`, {name: userData.name, email: userData.email, password: userData.password}, config);

          dispatch({ type: LoginTypes.REGISTER_USER_SUCCESS, payload: data.user });

     } catch (error: any) {
          dispatch({ type: LoginTypes.REGISTER_USER_FAIL, payload: error.response.data.message });
     }
};

//Load User
export const loadUser = () => async (dispatch: any) => {
     try {
          dispatch({ type: LoginTypes.LOAD_USER_REQUEST });

          const { data } = await axios.get(`/api/v1/me`);

          dispatch({ type: LoginTypes.LOAD_USER_SUCCESS, payload: data.user });
     } catch (error: any) {
          dispatch({ type: LoginTypes.LOAD_USER_FAIL, payload: error.response.data.message });
     }
};

//Logout User
export const logout = () => async (dispatch: any) => {
     try {
          await axios.get(`/api/v1/logout`);

          dispatch({ type: LoginTypes.LOGOUT_SUCCESS });
     } catch (error: any) {
          dispatch({ type: LoginTypes.LOGOUT_FAIL, payload: error.response.data.message });
     }
};

//Clearing Errors
export const clearErrors = () => async (dispatch: any) => {
     dispatch({
          type: LoginTypes.CLEAR_ERROR,
     });
};
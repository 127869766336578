import React, { useEffect, useState } from "react";
import { Col, Row, Button, Card, CardBody, CardTitle, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";

const UiLoader = (loadingStatus: any) => {
     document.title = "Offcanvas | IgMagnify - React Admin & Dashboard Template";

     const [open, setOpen] = useState(false);
     const [isTop, setIsTop] = useState(false);
     const [isRight, setIsRight] = useState(false);
     const [isBottom, setIsBottom] = useState(false);
     const [isEnableScroll, setIsEnableScroll] = useState(false);
     const [isBackdrop, setIsBackdrop] = useState(false);
     const [isScrollBackDrop, setIsScrollBackDrop] = useState(false);

     const toggleTopCanvas = () => {
          setIsTop(!isTop);
     };

     useEffect(() => {
        if (loadingStatus) toggleTopCanvas()
          
     }, [loadingStatus]);

     return (
          <>
               <Offcanvas isOpen={loadingStatus} direction="top" toggle={toggleTopCanvas}>
                    <OffcanvasBody>
                         <Row>
                              <Col sm={12} className="d-flex justify-content-center align-items-center">
                                   <div className="loader ">
                                        Please wait<span className="status__dot">.</span>
                                        <span className="status__dot">.</span>
                                        <span className="status__dot">.</span>
                                        <br />
                                        We are fetching data from server
                                   </div>
                              </Col>
                         </Row>
                    </OffcanvasBody>
               </Offcanvas>
          </>
     );
};

export default UiLoader;

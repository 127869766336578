import { LayoutTypes } from "./actionTypes";

export const changeLayout = (layout: any) => async (dispatch: any) => {
     try {
          dispatch({
               type: LayoutTypes.CHANGE_LAYOUT,
               payload: layout,
          });

          if (layout === "horizontal") {
               changeTopbarTheme("light");
               document.body.removeAttribute("data-sidebar");
               changeBodyAttribute("data-sidebar-size", "lg");
               if (document.body.getAttribute("data-layout-mode") === "dark") {
                    changeTopbarTheme("dark");
               }
          } else {
               changeTopbarTheme("light");
               if (document.body.getAttribute("data-layout-mode") === "dark") {
                    changeTopbarTheme("dark");
               }
          }
          changeBodyAttribute("data-layout", layout);
     } catch (error) {}
};

export const changePreloader = (layout: any) => ({
     type: LayoutTypes.CHANGE_PRELOADER,
     payload: layout,
});

export const changeLayoutWidth = (width: any) => async (dispatch: any) => {
     try {
          dispatch({
               type: LayoutTypes.CHANGE_LAYOUT_WIDTH,
               payload: width,
          });
          if (width === "boxed") {
               changeSidebarType("md");
               changeBodyAttribute("data-layout-size", width);
          } else if (width === "scrollable") {
               changeSidebarType("md");
          } else {
               changeSidebarType("lg");
               changeBodyAttribute("data-layout-size", width);
          }
     } catch (error) {}
};

export const changeSidebarTheme = (theme: any) => async (dispatch: any) => {
     try {
          dispatch({
               type: LayoutTypes.CHANGE_SIDEBAR_THEME,
               payload: theme,
          });

          changeBodyAttribute("data-sidebar", theme);
     } catch (error) {}
};

export const changeSidebarType = (sidebarType: any) => async (dispatch: any) => {
     try {
          dispatch({ type: LayoutTypes.CHANGE_SIDEBAR_TYPE, payload: { sidebarType } });

          changeLeftSidebarType({ payload: { sidebarType } });
     } catch (error) {}
};

export const changeTopbarTheme = (topbarTheme: any) => async (dispatch: any) => {     
     try {
          dispatch({ type: LayoutTypes.CHANGE_TOPBAR_THEME, payload: topbarTheme });

          changeBodyAttribute("data-topbar", topbarTheme)

     } catch (error) {}
};

export const changelayoutMode = (layoutMode: any, layoutType: any) => async (dispatch: any) => {
     try {
          dispatch({ type: LayoutTypes.CHANGE_LAYOUT_THEME, payload: { layoutMode, layoutType } });
          if (layoutMode === "light") {
               changeBodyAttribute("data-layout-mode", layoutMode);
               changeTopbarTheme("light");
               if (layoutType !== "horizontal") {
                    changeBodyAttribute("data-sidebar", "light");
               } else {
                    changeBodyAttribute("data-sidebar", "");
               }
          } else if (layoutMode === "dark") {
               changeBodyAttribute("data-layout-mode", layoutMode);
               changeTopbarTheme("dark");
               changeBodyAttribute("data-sidebar", "dark");
          }
     } catch (error) {}
};

export const changeLayoutPosition = (layoutPosition: any) => async (dispatch: any) => {
     try {
          dispatch({
               type: LayoutTypes.CHANGE_LAYOUT_POSITION,
               payload: layoutPosition,
          });
          changeBodyAttribute("data-layout-scrollable", layoutPosition);
     } catch (error) {}
};

export const showRightSidebarAction = (isopen: any) => (dispatch: any) => {
     try {
          dispatch({ type: LayoutTypes.SHOW_RIGHT_SIDEBAR, payload: isopen });

          manageBodyClass("right-bar-enabled", "add");
     } catch (error) {}
};

export const showSidebar = (isopen: any) => ({
     type: LayoutTypes.SHOW_SIDEBAR,
     payload: isopen,
});

export const toggleLeftmenu = (isopen: any) => ({
     type: LayoutTypes.TOGGLE_LEFTMENU,
     payload: isopen,
});

/**
 * Changes the left sidebar theme
 * @param {*} param0
 */
function changeLeftSidebarTheme({ payload: theme }: any) {
     try {
          changeBodyAttribute("data-sidebar", theme);
     } catch (error) {}
}

/**
 * Changes the left sidebar type
 * @param {*} param0
 */

function manageBodyClass(cssClass: any, action = "toggle") {
     switch (action) {
          case "add":
               if (document.body) document.body.classList.add(cssClass);
               break;
          case "remove":
               if (document.body) document.body.classList.remove(cssClass);
               break;
          default:
               if (document.body) document.body.classList.toggle(cssClass);
               break;
     }

     return true;
}

/**
 * Changes the body attribute
 */

function changeBodyAttribute(attribute: any, value: any) {
     if (document.body) document.body.setAttribute(attribute, value);
     return true;
}

/**
 * Changes the left sidebar type
 * @param {*} param0
 */
function changeLeftSidebarType({ payload: { sidebarType } }: any) {
     try {
          switch (sidebarType) {
               case "md":
                    manageBodyClass("sidebar-enable", "remove");
                    changeBodyAttribute("data-sidebar-size", "md");
                    break;
               case "sm":
                    manageBodyClass("sidebar-enable", "remove");
                    changeBodyAttribute("data-sidebar-size", "sm");
                    break;
               default:
                    manageBodyClass("sidebar-enable", "add");
                    changeBodyAttribute("data-sidebar-size", "lg");
                    break;
          }
     } catch (error) {}
}

import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";

import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import classnames from "classnames";
import img1 from "../../../assets/images/small/img-1.jpg";
import img2 from "../../../assets/images/small/img-2.jpg";
import { useDispatch, useSelector } from "react-redux";
import InstaPostFeed from "../../../components/Instagram/Post/index"
import InstaReelsFeed from "../../../components/Instagram/Reels/index"
import { clearInstaErrors, getUserFollowers, getUserFollowing, getUserPosts, getUserReelsPosts } from "src/store/instagram/action";
import { InstagramTypes } from "src/store/instagram/actionType";
import InstaFollowers from "../../../components/Instagram/Followers/index";
import InstaFollowing from "src/components/Instagram/Following/index";
import UiLoader from "src/components/UiLoader";

const ProfileFeed = ({insta_id}: any) => {

     const dispatch = useDispatch()

     const { loading, error, instaPosts } = useSelector((state: any) => state.userPosts);
     const { loading: instaReelsLoading, error: instaReelsError, instaReelsPosts } = useSelector((state: any) => state.userReels);
     const {loading: instaFollowersLoading, error: instaFollowersError, instaFollowers} = useSelector((state: any) => state.userFollowers)
     const {loading: instaFollowingLoading, error: instaFollowingError, instaFollowing} = useSelector((state: any) => state.userFollowing)

     const [customActiveTab, setcustomActiveTab] = useState("1");

     const toggleCustom = (tab: any) => {
          if (customActiveTab !== tab) {
               setcustomActiveTab(tab);
          }
     };

     const callReelsPosts = () =>{
          if(instaReelsPosts === null || undefined){            
               dispatch(getUserReelsPosts(insta_id))
          }
     }

     const callFollowersLists = () =>{    
          if(instaFollowers === null || undefined){            
               dispatch(getUserFollowers(insta_id))
          }
     }

     const callFollowingLists = () =>{    
          if(instaFollowing === null || undefined){            
               dispatch(getUserFollowing(insta_id))
          }
     }

     useEffect(()=>{
          dispatch({ type: InstagramTypes.INSTA_POSTS_RESET });
          dispatch({ type: InstagramTypes.INSTA_REELS_POSTS_RESET });
          dispatch({ type: InstagramTypes.INSTA_FOLLOWERS_RESET });
          dispatch({ type: InstagramTypes.INSTA_FOLLOWING_RESET });
     },[])

     useEffect(()=>{

          if(instaPosts === null || undefined){
               dispatch(getUserPosts(insta_id))
          }

          if(error){
               dispatch(clearInstaErrors())
          }

     }, [dispatch, error, instaPosts])

     document.title = "Profile Feed - IgMagnify.com";

     return (
          <React.Fragment>
               <Col lg={12}>
                    <Card>
                         <CardBody>
                              <Nav tabs className="nav-tabs-custom nav-justified">
                                   <NavItem>
                                        <NavLink
                                             style={{ cursor: "pointer" }}
                                             className={classnames({
                                                  active: customActiveTab === "1",
                                             })}
                                             onClick={() => {
                                                  toggleCustom("1");
                                             }}
                                        >
                                             <span className="d-block d-sm-none">
                                                  <i className="fas fa-home"></i>
                                             </span>
                                             <span className="d-none d-sm-block">POSTS</span>
                                        </NavLink>
                                   </NavItem>
                                   <NavItem>
                                        <NavLink
                                             style={{ cursor: "pointer" }}
                                             className={classnames({
                                                  active: customActiveTab === "2",
                                             })}
                                             onClick={() => {
                                                  toggleCustom("2");
                                                  callReelsPosts()
                                             }}
                                        >
                                             <span className="d-block d-sm-none">
                                                  <i className="far fa-user"></i>
                                             </span>
                                             <span className="d-none d-sm-block">REELS</span>
                                        </NavLink>
                                   </NavItem>
                                   <NavItem>
                                        <NavLink
                                             style={{ cursor: "pointer" }}
                                             className={classnames({
                                                  active: customActiveTab === "3",
                                             })}
                                             onClick={() => {
                                                  toggleCustom("3");
                                                  callFollowersLists()
                                             }}
                                        >
                                             <span className="d-block d-sm-none">
                                                  <i className="far fa-envelope"></i>
                                             </span>
                                             <span className="d-none d-sm-block">FOLLOWERS</span>
                                        </NavLink>
                                   </NavItem>
                                   <NavItem>
                                        <NavLink
                                             style={{ cursor: "pointer" }}
                                             className={classnames({
                                                  active: customActiveTab === "4",
                                             })}
                                             onClick={() => {
                                                  toggleCustom("4");
                                                  callFollowingLists()
                                             }}
                                        >
                                             <span className="d-block d-sm-none">
                                                  <i className="fas fa-cog"></i>
                                             </span>
                                             <span className="d-none d-sm-block">FOLLOWING</span>
                                        </NavLink>
                                   </NavItem>
                              </Nav>

                              <TabContent activeTab={customActiveTab} className="p-3 text-muted">
                                   <TabPane tabId="1">
                                        {
                                            loading ? <UiLoader loadingStatus={loading}/> : <InstaPostFeed />
                                        }
                                   </TabPane>
                                   <TabPane tabId="2">
                                        {
                                             instaReelsLoading ? <UiLoader loadingStatus={instaReelsLoading}/> : <InstaReelsFeed />
                                        }
                                   </TabPane>
                                   <TabPane tabId="3">
                                        {
                                             instaFollowersLoading ? <UiLoader loadingStatus={instaFollowersLoading}/> : <InstaFollowers/>
                                        }
                                   </TabPane>
                                   <TabPane tabId="4">
                                   {
                                             instaFollowingLoading ? <UiLoader loadingStatus={instaFollowingLoading}/> : <InstaFollowing/>
                                        }
                                   </TabPane>
                              </TabContent>
                         </CardBody>
                    </Card>
               </Col>
          </React.Fragment>
     );
};

export default ProfileFeed;

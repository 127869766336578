import {legacy_createStore as createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

let initialState = {
  
};

const middlewares = [thunk];

const composeEnhancers = composeWithDevTools({
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
  }) || compose;


const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middlewares)));

  export default store;

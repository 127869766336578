import React from "react";
import small from "../../../assets/images/small/img-2.jpg";
import small2 from "../../../assets/images/small/img-6.jpg";
import small3 from "../../../assets/images/small/img-1.jpg";
import users from "../../../assets/images/users/avatar-1.jpg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import DownloadVideo from "./DownloadVideo";

const Video = ({ data }: any) => {
     const { loading, error, userInfo } = useSelector((state: any) => state.userInfo);

     const randomFixedInteger = function (length: number) {
          return Math.floor(
               Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1)
          );
     };

     const playVideo = `https://app.igmagnify.com/api/v1/insta/video/view?url=${encodeURIComponent(
          data?.video_url
     )}&type=mp4&dlheader=video/mp4`;

     return (
          <>
               <div>
                    <span className="mb-0">
                         <img
                              src={`https://app.igmagnify.com/api/v1/insta/img/view?url=${window.btoa(
                                   userInfo?.profilePic
                              )}&type=jpeg&dlheader=image/jpeg`}
                              alt=""
                              className="rounded-circle header-profile-user post-title-head"
                         />
                         &nbsp;{userInfo?.fullName}
                    </span>
                    <DownloadVideo download={data?.video_url} />

                    <div className="position-relative mb-3 mt-3">
                         <video width="100%" controls autoPlay={false} controlsList="nodownload">
                              <source src={playVideo} type="video/mp4"></source>
                         </video>
                    </div>

                    <ul className="list-inline">
                         <li className="list-inline-item mr-3">
                              <Link to="#" className="text-muted">
                                   <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                                   {data?.edge_media_preview_like?.count} Likes
                              </Link>
                         </li>
                         <li className="list-inline-item mr-3">
                              <Link to="#" className="text-muted">
                                   <i className="bx bx-comment-dots align-middle text-muted me-1"></i>{" "}
                                   {data?.edge_media_to_comment?.count} Comments
                              </Link>
                         </li>
                    </ul>
                    <p>{data?.edge_media_to_caption?.edges[0]?.node.text}</p>

                    {/* <div>
                         <Link to="#" className="text-primary">
                              Read more <i className="mdi mdi-arrow-right"></i>
                         </Link>
                    </div> */}
               </div>

               <hr className="my-4" />
          </>
     );
};

export default Video;

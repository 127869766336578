import { LoginTypes } from "./actionTypes";

// const initialState = {
//   error: "",
//   loading: false,
// };

export const userReducer = (state = { user: {} }, action: any) => {
     switch (action.type) {
          case LoginTypes.LOGIN_REQUEST:
          case LoginTypes.REGISTER_USER_REQUEST:
          case LoginTypes.LOAD_USER_REQUEST:
               return {
                    loading: true,
                    isAuthenticated: false,
               };
          case LoginTypes.LOGIN_SUCCESS:
          case LoginTypes.REGISTER_USER_SUCCESS:
          case LoginTypes.LOAD_USER_SUCCESS:
               return {
                    ...state,
                    loading: false,
                    isAuthenticated: true,
                    user: action.payload,
               };
          case LoginTypes.LOGOUT_SUCCESS:
               return {
                    loading: false,
                    user: null,
                    isAuthenticated: false,
               };
          case LoginTypes.LOGIN_FAIL:
          case LoginTypes.REGISTER_USER_FAIL:
               return {
                    ...state,
                    loading: false,
                    isAuthenticated: false,
                    user: null,
                    error: action.payload,
               };
          case LoginTypes.LOAD_USER_FAIL:
               return {
                    loading: false,
                    isAuthenticated: false,
                    user: null,
                    error: action.payload,
               };
          case LoginTypes.LOGOUT_FAIL:
               return {
                    ...state,
                    loading: false,
                    error: action.payload,
               };
          case LoginTypes.CLEAR_ERROR:
               return {
                    ...state,
                    error: null,
               };

          default:
               return state;
     }
};
import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle, Container, Table } from "reactstrap";
import { Link } from "react-router-dom";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import users from "../../assets/images/users/avatar-1.jpg";

const Followers = () => {
     return (
          <React.Fragment>
               <div className="page-content">
                    <Container fluid>
                         {/* Render Breadcrumbs */}
                         <Breadcrumbs title="Insta" breadcrumbItem="Followers" />
                         <Card>
                              <CardBody>
                                   {/* <CardTitle className="mb-4">Followers List</CardTitle> */}

                                   <div className="table-responsive">
                                        <Table className="table align-middle table-nowrap">
                                             {/* {FollowingListMember.map((item, key) => ( */}
                                             <tbody key={""}>
                                                  <tr>
                                                       <td>
                                                            {/* {item.img ? */}
                                                            <img
                                                                 src={users}
                                                                 className="rounded-circle avatar-xs"
                                                                 alt=""
                                                            />
                                                            :
                                                            {/* <div className="avatar-xs">
                                        <span className="avatar-title rounded-circle bg-primary text-white font-size-16">
                                            {users}
                                        </span>
                                    </div> */}
                                                            {/* } */}
                                                       </td>
                                                       <td style={{ width: "75%" }}>
                                                            <h5 className="font-size-14 m-0">
                                                                 <Link to="#" className="text-dark">
                                                                      {"Daniel Canales"}
                                                                 </Link>
                                                            </h5>
                                                       </td>
                                                       <td>
                                                            <div>
                                                                 <Link
                                                                      to="#"
                                                                      className="badge bg-primary font-size-15 p-2 me-1"
                                                                 >
                                                                      {"Remove"}
                                                                 </Link>
                                                                 <Link
                                                                      to="#"
                                                                      className="badge bg-primary font-size-15 p-2 me-1"
                                                                 >
                                                                      {"Following"}
                                                                 </Link>
                                                                 {/* <Link to="#" className="badge bg-primary font-size-15 p-2">{item.label_3}</Link> */}
                                                            </div>
                                                       </td>
                                                  </tr>
                                             </tbody>
                                             {/* ))} */}
                                        </Table>
                                   </div>
                              </CardBody>
                         </Card>
                    </Container>
               </div>
          </React.Fragment>
     );
};

export default Followers;

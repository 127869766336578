import {ForgetPwdTypes} from "./actionTypes"

const forgotPasswordReducer = (state = {}, action: any) => {
  switch (action.type) {
       case ForgetPwdTypes.FORGOT_PASSWORD_REQUEST:
       case ForgetPwdTypes.RESET_PASSWORD_REQUEST:
            return {
                 ...state,
                 loading: true,
                 error: null,
            };
       case ForgetPwdTypes.FORGOT_PASSWORD_SUCCESS:
            return {
                 ...state,
                 loading: false,
                 message: action.payload,
            };
       case ForgetPwdTypes.RESET_PASSWORD_SUCCESS:
            return {
                 ...state,
                 loading: false,
                 success: action.payload,
            };
       case ForgetPwdTypes.FORGOT_PASSWORD_FAIL:
       case ForgetPwdTypes.RESET_PASSWORD_FAIL:
            return {
                 ...state,
                 loading: false,
                 error: action.payload,
            };
       case ForgetPwdTypes.CLEAR_ERROR:
            return {
                 ...state,
                 error: null,
            };

       default:
            return state;
  }
};

export default forgotPasswordReducer

import React from "react";
import small from "../../../assets/images/small/img-2.jpg";
import small2 from "../../../assets/images/small/img-6.jpg";
import small3 from "../../../assets/images/small/img-1.jpg";
import users from "../../../assets/images/users/avatar-1.jpg";
import { Link } from "react-router-dom";
import Image from "./Image";
import Video from "./Video";

const Timeline = ({Post}: any) => {
     
     const {node} = Post;
     
     return (
          <>
          {
               node.is_video ? <Video data={node}/> : <Image data={node}/>
          }
          </>
     );
};

export default Timeline;

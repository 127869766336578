import { InstagramState, InstagramTypes } from "./actionType";

export const instaReducer = (state = { userID: "" }, action: any) => {
     switch (action.type) {
          case InstagramTypes.INSTA_REQUEST:
               return {
                    ...state,
                    loading: true,
               };
          case InstagramTypes.INSTA_SUCCESS:
               return {
                    ...state,
                    loading: false,
                    userID: action.payload,
               };
          case InstagramTypes.INSTA_FAIL:
               return {
                    ...state,
                    loading: false,
                    userID: null,
                    error: action.payload,
               };
          case InstagramTypes.INSTA_RESET:
               return {
                    ...state,
                    loading: false,
                    userID: null,
                    error: null,
               };
          case InstagramTypes.CLEAR_ERROR:
               return {
                    ...state,
                    error: null,
               };
          default:
               return state;
     }
};

export const instaUserInfoReducer = (state = { userInfo: {} }, action: any) => {
     switch (action.type) {
          case InstagramTypes.INSTA_USER_INFO_REQUEST:
               return {
                    ...state,
                    loading: true,
               };
          case InstagramTypes.INSTA_USER_INFO_SUCCESS:
               return {
                    ...state,
                    loading: false,
                    userInfo: action.payload,
               };
          case InstagramTypes.INSTA_USER_INFO_RESET:
               return {
                    ...state,
                    loading: false,
                    userInfo: null,
                    error: null,
               };
          case InstagramTypes.INSTA_USER_INFO_FAIL:
               return {
                    ...state,
                    loading: false,
                    userInfo: null,
                    error: action.payload,
               };
          case InstagramTypes.CLEAR_ERROR:
               return {
                    ...state,
                    error: null,
               };
          default:
               return state;
     }
};

export const instaUserFollowersReducer = (state = { instaFollowers: {} }, action: any) => {
     switch (action.type) {
          case InstagramTypes.INSTA_FOLLOWERS_REQUEST:
               return {
                    ...state,
                    loading: true,
               };
          case InstagramTypes.INSTA_FOLLOWERS_SUCCESS:
               return {
                    ...state,
                    loading: false,
                    instaFollowers: action.payload,
               };
          case InstagramTypes.INSTA_FOLLOWERS_RESET:
               return {
                    ...state,
                    loading: false,
                    instaFollowers: null,
                    error: null,
               };
          case InstagramTypes.INSTA_FOLLOWERS_FAIL:
               return {
                    ...state,
                    loading: false,
                    instaFollowers: null,
                    error: action.payload,
               };
          case InstagramTypes.CLEAR_ERROR:
               return {
                    ...state,
                    error: null,
               };
          default:
               return state;
     }
};

export const instaUserFollowingReducer = (state = { instaFollowing: {} }, action: any) => {
     switch (action.type) {
          case InstagramTypes.INSTA_FOLLOWING_REQUEST:
               return {
                    ...state,
                    loading: true,
               };
          case InstagramTypes.INSTA_FOLLOWING_SUCCESS:
               return {
                    ...state,
                    loading: false,
                    instaFollowing: action.payload,
               };
          case InstagramTypes.INSTA_FOLLOWING_RESET:
               return {
                    ...state,
                    loading: false,
                    instaFollowing: null,
                    error: null,
               };
          case InstagramTypes.INSTA_FOLLOWING_FAIL:
               return {
                    ...state,
                    loading: false,
                    instaFollowing: null,
                    error: action.payload,
               };
          case InstagramTypes.CLEAR_ERROR:
               return {
                    ...state,
                    error: null,
               };
          default:
               return state;
     }
};

export const instaUserPostsReducer = (state = { instaPosts: {} }, action: any) => {
     switch (action.type) {
          case InstagramTypes.INSTA_POSTS_REQUEST:
               return {
                    ...state,
                    loading: true,
               };
          case InstagramTypes.INSTA_POSTS_SUCCESS:
               return {
                    ...state,
                    loading: false,
                    instaPosts: action.payload,
               };
          case InstagramTypes.INSTA_POSTS_RESET:
               return {
                    ...state,
                    loading: false,
                    instaPosts: null,
                    error: null,
               };
          case InstagramTypes.INSTA_POSTS_FAIL:
               return {
                    ...state,
                    loading: false,
                    instaPosts: null,
                    error: action.payload,
               };
          case InstagramTypes.CLEAR_ERROR:
               return {
                    ...state,
                    error: null,
               };
          default:
               return state;
     }
};

export const instaUserReelsPostsReducer = (state = { instaReelsPosts: {} }, action: any) => {
     switch (action.type) {
          case InstagramTypes.INSTA_REELS_POSTS_REQUEST:
               return {
                    ...state,
                    loading: true,
               };
          case InstagramTypes.INSTA_REELS_POSTS_SUCCESS:
               return {
                    ...state,
                    loading: false,
                    instaReelsPosts: action.payload,
               };
          case InstagramTypes.INSTA_REELS_POSTS_RESET:
               return {
                    ...state,
                    loading: false,
                    instaReelsPosts: null,
                    error: null,
               };
          case InstagramTypes.INSTA_REELS_POSTS_FAIL:
               return {
                    ...state,
                    loading: false,
                    instaReelsPosts: null,
                    error: action.payload,
               };
          case InstagramTypes.CLEAR_ERROR:
               return {
                    ...state,
                    error: null,
               };
          default:
               return state;
     }
};

import { ProfileTypes } from "./actionTypes";

const initialState = {
     error: "",
     success: "",
};

const profile = (state = {}, action: any) => {
     switch (action.type) {
          case ProfileTypes.UPDATE_PROFILE_REQUEST:
          case ProfileTypes.UPDATE_PASSWORD_REQUEST:
               return {
                    ...state,
                    loading: true,
               };
          case ProfileTypes.UPDATE_PROFILE_SUCCESS:
          case ProfileTypes.UPDATE_PASSWORD_SUCCESS:
               return {
                    ...state,
                    loading: false,
                    isUpdated: action.payload,
               };
          case ProfileTypes.UPDATE_PROFILE_FAIL:
          case ProfileTypes.UPDATE_PASSWORD_FAIL:
               return {
                    ...state,
                    loading: false,
                    error: action.payload,
               };
          case ProfileTypes.UPDATE_PROFILE_RESET:
          case ProfileTypes.UPDATE_PASSWORD_RESET:
               return {
                    ...state,
                    isUpdated: false,
               };
          case ProfileTypes.CLEAR_ERROR:
               return {
                    ...state,
                    error: null,
               };

          default:
               return state;
     }
};

export default profile;

import axios, { AxiosRequestConfig } from "axios";
const retry = require("retry");
import { InstagramTypes } from "./actionType";

export const getInstaUserID = (user_id: any, history: any) => async (dispatch: any) => {
     try {
          dispatch({ type: InstagramTypes.INSTA_REQUEST });

          const operation = retry.operation({
               retries: 5,
               factor: 3,
               minTimeout: 1 * 1000,
               maxTimeout: 60 * 1000,
               randomize: true,
          });

          operation.attempt(async (currentAttempt: any) => {
               try {
                    const config = { headers: { "Content-Type": "application/json" } };

                    const { data } = await axios.get(`/api/v1/insta/user/${user_id.searchText}`, config);

                    dispatch({ type: InstagramTypes.INSTA_SUCCESS, payload: data.data });
               } catch (error) {
                    if (operation.retry(error)) {
                         return;
                    }
               }
          });
     } catch (error: any) {
          dispatch({ type: InstagramTypes.INSTA_FAIL, payload: error.response.data.message });
     }
};

export const getInstaUserInfo = (insta_id: any) => async (dispatch: any) => {
     try {
          dispatch({ type: InstagramTypes.INSTA_USER_INFO_REQUEST });

          const operation = retry.operation({
               retries: 5,
               factor: 3,
               minTimeout: 1 * 1000,
               maxTimeout: 60 * 1000,
               randomize: true,
          });

          operation.attempt(async (currentAttempt: any) => {
               try {
                    const config = { headers: { "Content-Type": "application/json" } };

                    const { data } = await axios.get(`/api/v1/insta/user/profile/${insta_id}`, config);

                    dispatch({ type: InstagramTypes.INSTA_USER_INFO_SUCCESS, payload: data });
               } catch (error) {
                    if (operation.retry(error)) {
                         return;
                    }
               }
          });
     } catch (error: any) {
          dispatch({ type: InstagramTypes.INSTA_USER_INFO_FAIL, payload: error.response.data.message });
     }
};

export const getUserFollowers = (insta_id: any) => async (dispatch: any) => {
     try {
          dispatch({ type: InstagramTypes.INSTA_FOLLOWERS_REQUEST });

          const operation = retry.operation({
               retries: 5,
               factor: 3,
               minTimeout: 1 * 1000,
               maxTimeout: 60 * 1000,
               randomize: true,
          });

          operation.attempt(async (currentAttempt: any) => {
               try {
                    const config = { headers: { "Content-Type": "application/json" } };

                    const { data } = await axios.get(`/api/v1/insta/user/followers/${insta_id}`, config);

                    //localStorage.setItem("insta_UserId", data.data.id)

                    dispatch({ type: InstagramTypes.INSTA_FOLLOWERS_SUCCESS, payload: data });
               } catch (error) {
                    if (operation.retry(error)) {
                         return;
                    }
               }
          });
     } catch (error: any) {
          dispatch({ type: InstagramTypes.INSTA_FOLLOWERS_FAIL, payload: error.response.data.message });
     }
};

export const getUserFollowing = (insta_id: any) => async (dispatch: any) => {
     try {
          dispatch({ type: InstagramTypes.INSTA_FOLLOWING_REQUEST });
          const operation = retry.operation({
               retries: 5,
               factor: 3,
               minTimeout: 1 * 1000,
               maxTimeout: 60 * 1000,
               randomize: true,
          });

          operation.attempt(async (currentAttempt: any) => {
               try {
                    const config = { headers: { "Content-Type": "application/json" } };

                    const { data } = await axios.get(`/api/v1/insta/user/followers/${insta_id}`, config);

                    //localStorage.setItem("insta_UserId", data.data.id)

                    dispatch({ type: InstagramTypes.INSTA_FOLLOWING_SUCCESS, payload: data });
               } catch (error) {
                    if (operation.retry(error)) {
                         return;
                    }
               }
          });
     } catch (error: any) {
          dispatch({ type: InstagramTypes.INSTA_FOLLOWING_FAIL, payload: error.response.data.message });
     }
};

export const getUserPosts = (insta_id: any) => async (dispatch: any) => {
     try {
          dispatch({ type: InstagramTypes.INSTA_POSTS_REQUEST });

          const operation = retry.operation({
               retries: 5,
               factor: 3,
               minTimeout: 1 * 1000,
               maxTimeout: 60 * 1000,
               randomize: true,
          });

          operation.attempt(async (currentAttempt: any) => {
               try {
                    // console.log('sending request: ', currentAttempt, ' attempt');

                    const config = { headers: { "Content-Type": "application/json" } };

                    const { data } = await axios.get(`/api/v1/insta/user/posts/${insta_id}`, config);

                    dispatch({ type: InstagramTypes.INSTA_POSTS_SUCCESS, payload: data });
               } catch (error) {
                    if (operation.retry(error)) {
                         return;
                    }
               }
          });
     } catch (error: any) {
          dispatch({ type: InstagramTypes.INSTA_POSTS_FAIL, payload: error.response.data.message });
     }
};

export const getUserReelsPosts = (insta_id: any) => async (dispatch: any) => {
     try {
          dispatch({ type: InstagramTypes.INSTA_REELS_POSTS_REQUEST });

          const operation = retry.operation({
               retries: 5,
               factor: 3,
               minTimeout: 1 * 1000,
               maxTimeout: 60 * 1000,
               randomize: true,
          });

          operation.attempt(async (currentAttempt: any) => {
               try {
                    const config = { headers: { "Content-Type": "application/json" } };

                    const { data } = await axios.get(`/api/v1/insta/user/reels/posts/${insta_id}`, config);

                    dispatch({ type: InstagramTypes.INSTA_REELS_POSTS_SUCCESS, payload: data });
               } catch (error) {
                    if (operation.retry(error)) {
                         return;
                    }
               }
          });
     } catch (error: any) {
          dispatch({ type: InstagramTypes.INSTA_REELS_POSTS_FAIL, payload: error.response.data.message });
     }
};

//Clearing Errors
export const clearInstaErrors = () => async (dispatch: any) => {
     dispatch({
          type: InstagramTypes.CLEAR_ERROR,
     });
};

import axios from "axios";
import { ProfileTypes } from "./actionTypes";

//Update Profile
export const updateProfile = (userData: any) => async (dispatch: any) => {
     try {
          dispatch({ type: ProfileTypes.UPDATE_PROFILE_REQUEST });

          const config = { headers: { "content-type": "multipart/form-data", Accept: "multipart/form-data" } };

          const { data } = await axios.put(`/api/v1/me/update`, userData, config);

          dispatch({ type: ProfileTypes.UPDATE_PROFILE_SUCCESS, payload: data.success });
     } catch (error: any) {
          dispatch({ type: ProfileTypes.UPDATE_PROFILE_FAIL, payload: error.response.data.message });
     }
};

//Update Password
export const updatePassword = (passwords: any) => async (dispatch: any) => {
     try {
          dispatch({ type: ProfileTypes.UPDATE_PASSWORD_REQUEST });

          const config = { headers: { "Content-Type": "application/json" } };

          const { data } = await axios.put(`/api/v1/password/update`, passwords, config);

          dispatch({ type: ProfileTypes.UPDATE_PASSWORD_SUCCESS, payload: data.success });
     } catch (error: any) {
          dispatch({ type: ProfileTypes.UPDATE_PASSWORD_FAIL, payload: error.response.data.message });
     }
};

//Clearing Errors
export const clearProfileErrors = () => async (dispatch: any) => {
     dispatch({
          type: ProfileTypes.CLEAR_ERROR,
     });
};

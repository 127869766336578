import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback } from "react";

//Import Icons
import Icon from "@ailibs/feather-react-ts";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

//Import images
import giftBox from "../../assets/images/giftbox.png";

//i18n
import { withTranslation } from "react-i18next";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

const SidebarContent = (props: any) => {

  const{userInfo} = useSelector((state: any)=>state.userInfo)

  const ref = useRef<any>();

  const activateParentDropdown = useCallback((item : any) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul: any = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/dashboard" className="">
                <Icon name="home" />
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            <li>
              <Link to="/insta/search" className="">
                <Icon name="search" />
                <span>{props.t("Search User")}</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/insta/posts" className="">
                <Icon name="briefcase" />
                <span>{props.t("Posts")}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/insta/reels" className="">
                <Icon name="file-text" />
                <span>{props.t("Reels")}</span>
              </Link>
            </li> */}

            {/* <li>
              <Link to="/#" className="has-arrow">
                <Icon name="users" />
                <span>{props.t("Authentication")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Login")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Register")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Recover Password")}
                  </Link>
                </li>
                <li>
                  <Link to="#">{props.t("Lock Screen")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Confirm Mail")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Email Verification")}
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Two Step Verification")}
                  </Link>
                </li>
              </ul>
            </li> */}

            <li className="menu-title">{props.t("Profile")}</li>

            {/* <li>
              <Link to="/post" className="has-arrow ">
                <Icon name="briefcase" />
                <span>{props.t("Posts")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/post">{props.t("Posts")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Buttons")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Cards")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Carousel")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Dropdowns")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Grid")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Images")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Modals")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Offcanvas")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Progress Bars")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Placeholders")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Tabs & Accordions")}
                  </Link>
                </li>
                <li>
                  <Link to="#">{props.t("Typography")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Toasts")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Video")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("General")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Colors")}</Link>
                </li>
              </ul>
            </li> */}

            {/* <li>
              <Link to="/insta/profile" className=" ">
                <Icon name="gift" />
                <span className="badge rounded-pill bg-soft-danger text-danger float-end">
                  {userInfo?.followers ? userInfo?.followers : 0}
                </span>
                <span>{props.t("Followers")}</span>
              </Link>
              
            </li> */}

            <li>
              <Link to="" className=" ">
                <Icon name="gift" />
                <span className="badge rounded-pill bg-soft-danger text-danger float-end">
                  {userInfo?.followers ? userInfo?.followers : 0}
                </span>
                <span>{props.t("Followers")}</span>
              </Link>
              
            </li>

            <li>
              <Link to="" className="">
                <Icon name="box" />
                <span className="badge rounded-pill bg-soft-danger text-danger float-end">
                  {userInfo?.following ? userInfo?.following : 0}
                </span>
                <span>{props.t("Following")}</span>
              </Link>
            </li>

            <li>
              <Link to="" className="">
                <Icon name="users" />
                <span className="badge rounded-pill bg-soft-danger text-danger float-end">
                  {userInfo?.posts ? userInfo?.posts : 0}
                </span>
                <span>{props.t("Post")}</span>
              </Link>
            </li>

            {/* <li>
              <Link to="/#" className="has-arrow ">
                <Icon name="map" />
                <span>{props.t("Notifications")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Google")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Vector")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Leaflet")}</Link>
                </li>
              </ul>
            </li> */}

            {/* <li>
              <Link to="/#" className="has-arrow ">
                <Icon name="share-2" />
                <span>{props.t("Create")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Level 1.1")}</Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {props.t("Level 1.2")}
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/#">{props.t("Level 2.1")}</Link>
                    </li>
                    <li>
                      <Link to="/#">{props.t("Level 2.2")}</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}
          </ul>
          <div className="card sidebar-alert border-0 text-center mx-4 mb-0 mt-5">
            <div className="card-body">
              <img src={giftBox} alt="" />
              <div className="mt-4">
                <h5 className="alertcard-title font-size-16">
                  Unlimited Access
                </h5>
                <p className="font-size-13">
                  Upgrade your plan from a Free trial, to select ‘Business
                  Plan’.
                </p>
                <a href="#!" className="btn btn-primary mt-2">
                  Upgrade Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(SidebarContent));
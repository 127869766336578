import React, { useEffect, useState } from "react";
import {
     Container,
     Card,
     CardBody,
     CardHeader,
     Col,
     Row,
     FormGroup,
     Label,
     Input,
     Button,
     FormFeedback,
     Form,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import { loadUser } from "../../store/auth/login/actions";
import { clearProfileErrors, updateProfile } from "../../store/auth/profile/actions";
import { ProfileTypes } from "src/store/auth/profile/actionTypes";

const UpdateProfile = () => {
     const { user } = useSelector((state: any) => state.login);
     const { error, isUpdated, loading } = useSelector((state: any) => state.profile);

     const dispatch = useDispatch();
     const history = useHistory();

     const [name, setName] = useState("");
     const [email, setEmail] = useState("");

     const [avatar, setAvatar] = useState<any>("/profile/rohit-sharma.jpg");
     const [avatarPreview, setAvatarPreview] = useState<any>(user.avatar.url);

     // Form validation
     const validation = useFormik({
          // enableReinitialize : use this flag when initial values needs to be changed
          enableReinitialize: true,

          initialValues: {
               email: email,
               name: name,
               avatar: avatar,
          },
          validationSchema: Yup.object({
               email: Yup.string().required("Please Enter Your Email"),
               name: Yup.string().required("Please Enter Your Name"),
               // avatar: Yup.string().required("Please Select Pic"),
          }),
          onSubmit: (values: any) => {
               
               const formData = new FormData();
             
               for (let value in values) {
                    formData.append(value, values[value]);
               }
               
               dispatch(updateProfile(formData));
          },
     });

     const updateProfileDataChange = (e: any) => {
          const reader = new FileReader();

          reader.onload = () => {
               if (reader.readyState === 2) {
                    setAvatarPreview(reader.result);
                    setAvatar(reader.result);
               }
          };

          reader.readAsDataURL(e.target.files[0]);
     };

     useEffect(() => {
          if (user) {
               setName(user.name);
               setEmail(user.email);
               setAvatarPreview(user.avatar.url);
          }

          if (error) {
               const interval = setInterval(() => {
                    dispatch(clearProfileErrors());
               }, 2000);

               return () => clearInterval(interval);
          }

          if (isUpdated) {
               dispatch(loadUser());

               history.push("/user/profile");

               dispatch({
                    type: ProfileTypes.UPDATE_PROFILE_RESET,
               });
          }
     }, [dispatch, error, alert, user, isUpdated, history]);

     return (
          <React.Fragment>
               <div className="page-content">
                    <Container fluid>
                         {/* Render Breadcrumb */}
                         <Breadcrumb title="User" breadcrumbItem="Update Profile" />

                         <Row>
                              <Col xl={6}>
                                   <Card>
                                        {/* <CardHeader>
                                             <h4 className="card-title">React Validation - Normal</h4>
                                             <p className="card-title-desc">
                                                  Provide valuable, actionable feedback to your users with HTML5 form
                                                  validation–available in all our supported browsers.
                                             </p>
                                        </CardHeader> */}
                                        <CardBody>
                                             <Form
                                                  className="needs-validation"
                                                  onSubmit={(e) => {
                                                       e.preventDefault();
                                                       validation.handleSubmit();
                                                       return false;
                                                  }}
                                                  encType="multipart/form-data"
                                             >
                                                  <Row>
                                                       <Col md="12">
                                                            <FormGroup className="mb-3">
                                                                 <Label htmlFor="validationCustom01">Name</Label>
                                                                 <Input
                                                                      name="name"
                                                                      placeholder="First name"
                                                                      type="text"
                                                                      className="form-control"
                                                                      id="validationCustom01"
                                                                      onChange={validation.handleChange}
                                                                      onBlur={validation.handleBlur}
                                                                      value={validation.values.name || user.name}
                                                                      invalid={
                                                                           validation.touched.name &&
                                                                           validation.errors.name
                                                                                ? true
                                                                                : false
                                                                      }
                                                                 />
                                                                 {validation.touched.name && validation.errors.name ? (
                                                                      <FormFeedback type="invalid">
                                                                           {validation.errors.name}
                                                                      </FormFeedback>
                                                                 ) : null}
                                                            </FormGroup>
                                                       </Col>
                                                       <Col md="12">
                                                            <FormGroup className="mb-3">
                                                                 <Label htmlFor="validationCustom02">Email</Label>
                                                                 <Input
                                                                      name="email"
                                                                      placeholder="Last name"
                                                                      type="email"
                                                                      className="form-control"
                                                                      id="validationCustom02"
                                                                      onChange={validation.handleChange}
                                                                      onBlur={validation.handleBlur}
                                                                      value={validation.values.email || user.email}
                                                                      invalid={
                                                                           validation.touched.email &&
                                                                           validation.errors.email
                                                                                ? true
                                                                                : false
                                                                      }
                                                                 />
                                                                 {validation.touched.email &&
                                                                 validation.errors.email ? (
                                                                      <FormFeedback type="invalid">
                                                                           {validation.errors.email}
                                                                      </FormFeedback>
                                                                 ) : null}
                                                            </FormGroup>
                                                       </Col>
                                                  </Row>
                                                  <Row>
                                                       <Col md="12">
                                                            <FormGroup className="mb-3">
                                                                 <img src={avatarPreview} alt="Avatar Preview" />
                                                            </FormGroup>
                                                       </Col>
                                                       <Col md="12">
                                                            <FormGroup className="mb-3">
                                                                 <Label htmlFor="validationCustom03">Image</Label>
                                                                 <Input
                                                                      name="avatar"
                                                                      placeholder="select file"
                                                                      type="file"
                                                                      className="form-control"
                                                                      accept="image/*"
                                                                      onChange={updateProfileDataChange}
                                                                 />
                                                            </FormGroup>
                                                       </Col>
                                                  </Row>

                                                  <Button color="primary" type="submit">
                                                       Submit form
                                                  </Button>
                                             </Form>
                                        </CardBody>
                                   </Card>
                              </Col>
                         </Row>
                    </Container>
               </div>
          </React.Fragment>
     );
};

export default UpdateProfile;

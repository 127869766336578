import axios from "axios";
import {ForgetPwdTypes} from "./actionTypes"


//Forgot Password
export const forgotPassword = (email: any, history: any) => async (dispatch: any) => {
  try {
       dispatch({ type: ForgetPwdTypes.FORGOT_PASSWORD_REQUEST });

       const config = { headers: { "Content-Type": "application/json" } };

       const { data } = await axios.post(`/api/v1/password/forgot`, email, config);

       dispatch({ type: ForgetPwdTypes.FORGOT_PASSWORD_SUCCESS, payload: data.message });
  } catch (error: any) {
       dispatch({ type: ForgetPwdTypes.FORGOT_PASSWORD_FAIL, payload: error.response.data.message });
  }
};

//Reset Password
export const resetPassword = (token: any, passwords: any, history: any) => async (dispatch: any) => {
  try {
       dispatch({ type: ForgetPwdTypes.RESET_PASSWORD_REQUEST });

       const config = { headers: { "Content-Type": "application/json" } };

       const { data } = await axios.put(`/api/v1/password/reset/${token}`, passwords, config);

       dispatch({ type: ForgetPwdTypes.RESET_PASSWORD_SUCCESS, payload: data.success });

       alert("Password updated successfully!");

       history.push('/dashboard')

  } catch (error: any) {
       dispatch({ type: ForgetPwdTypes.RESET_PASSWORD_FAIL, payload: error.response.data.message });
  }
};

//Clearing Errors
export const clearForgetPwdErrors = () => async (dispatch: any) => {
  dispatch({
       type: ForgetPwdTypes.CLEAR_ERROR,
  });
};

// Interface for Instagram Scraper

export enum InstagramTypes {
     INSTA_REQUEST = '@@instagram/INSTA_REQUEST',
     INSTA_SUCCESS ='@@instagram/INSTA_SUCCESS',
     INSTA_RESET = '@@instagram/INSTA_RESET',
     INSTA_FAIL = '@@instagram/INSTA_FAIL',

     INSTA_USER_INFO_REQUEST = '@@instagram/INSTA_USER_INFO_REQUEST',
     INSTA_USER_INFO_SUCCESS ='@@instagram/INSTA_USER_INFO_SUCCESS',
     INSTA_USER_INFO_RESET ='@@instagram/INSTA_USER_INFO_RESET',
     INSTA_USER_INFO_FAIL = '@@instagram/INSTA_USER_INFO_FAIL',

     INSTA_FOLLOWERS_REQUEST = '@@instagram/INSTA_FOLLOWERS_REQUEST',
     INSTA_FOLLOWERS_SUCCESS = '@@instagram/INSTA_FOLLOWERS_SUCCESS',
     INSTA_FOLLOWERS_RESET = '@@instagram/INSTA_FOLLOWERS_RESET',
     INSTA_FOLLOWERS_FAIL = '@@instagram/INSTA_FOLLOWERS_FAIL',

     INSTA_FOLLOWING_REQUEST = '@@instagram/INSTA_FOLLOWING_REQUEST',
     INSTA_FOLLOWING_SUCCESS = '@@instagram/INSTA_FOLLOWING_SUCCESS',
     INSTA_FOLLOWING_RESET = '@@instagram/INSTA_FOLLOWING_RESET',
     INSTA_FOLLOWING_FAIL = '@@instagram/INSTA_FOLLOWING_FAIL',

     INSTA_POSTS_REQUEST = '@@instagram/INSTA_POSTS_REQUEST',
     INSTA_POSTS_SUCCESS = '@@instagram/INSTA_POSTS_SUCCESS',
     INSTA_POSTS_RESET = '@@instagram/INSTA_POSTS_RESET',
     INSTA_POSTS_FAIL = '@@instagram/INSTA_POSTS_FAIL',

     INSTA_REELS_POSTS_REQUEST = '@@instagram/INSTA_REELS_POSTS_REQUEST',
     INSTA_REELS_POSTS_SUCCESS = '@@instagram/INSTA_REELS_POSTS_SUCCESS',
     INSTA_REELS_POSTS_RESET = '@@instagram/INSTA_REELS_POSTS_RESET',
     INSTA_REELS_POSTS_FAIL = '@@instagram/INSTA_REELS_POSTS_FAIL',

     CLEAR_ERROR = '@@instagram/CLEAR_ERROR',
}

export interface InstagramState{
     userID: number | null,
     instaFollowers: {},
     instaFollowing: {},
}
import React, { useEffect, useState } from "react";

//import Breadcrumbs
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Container, Col, Row } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { InstagramTypes } from "src/store/instagram/actionType";
import InstaProfileOverview from "./InstaProfileOverview";
import { clearInstaErrors, getInstaUserID, getInstaUserInfo, getUserPosts } from "src/store/instagram/action";
import ProfileFeed from "./InstaProfileFeed";
import UiLoader from "src/components/UiLoader";

const InstaProfile = () => {
     const history = useHistory();
     let { username }: any = useParams();
     const dispatch = useDispatch();

     const { loading, error, userInfo } = useSelector((state: any) => state.userInfo);

     useEffect(() => {
          if (username === false) {
               history.push("/");
          } else {
               dispatch({ type: InstagramTypes.INSTA_RESET });
          }

          if (error) {
               alert(error);
               clearInstaErrors();
               history.push("/insta/profile");
          }
          // dispatch(getInstaUserInfo(username));
     }, [history, error, alert]);

     document.title = "Profile - IgMagnify.com";
     return (
          <React.Fragment>
               <div className="page-content">
                    <Container fluid>
                         <Breadcrumbs title="Insta" breadcrumbItem="Profile" />
                         <Row>
                              <Col>
                                   <Row>
                                        {loading ? (
                                             <UiLoader loadingStatus={loading}/>
                                        ) : (
                                             <>
                                                  <InstaProfileOverview /> 
                                                  <ProfileFeed insta_id={username} />
                                             </>
                                        )}
                                   </Row>
                              </Col>
                         </Row>
                    </Container>
               </div>
          </React.Fragment>
     );
};

export default InstaProfile;

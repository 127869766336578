/**
 *  interface for Login Type
*/
export enum ProfileTypes {
    UPDATE_PROFILE_REQUEST = "@@profile/UPDATE_PROFILE_REQUEST",
    UPDATE_PROFILE_SUCCESS= "@@profile/UPDATE_PROFILE_SUCCESS",
    UPDATE_PROFILE_RESET = "@@profile/UPDATE_PROFILE_RESET",
    UPDATE_PROFILE_FAIL = "@@profile/UPDATE_PROFILE_FAIL",

    UPDATE_PASSWORD_REQUEST = "@@profile/UPDATE_PASSWORD_REQUEST",
    UPDATE_PASSWORD_SUCCESS= "@@profile/UPDATE_PASSWORD_SUCCESS",
    UPDATE_PASSWORD_RESET = "@@profile/UPDATE_PASSWORD_RESET",
    UPDATE_PASSWORD_FAIL = "@@profile/UPDATE_PASSWORD_FAIL",

    CLEAR_ERROR = "@@profile/CLEAR_ERROR",

}

import axios from "axios";
import React, { useEffect, useState } from "react";

const Test = () => {
     const [imgUrl, setImgUrl] = useState();

     // const rohit = async () => {
     //      const response = await fetch("/api/v1/insta/show/img/43443");

     //      //const stream = await response;
     //      //setImgUrl(stream)
     //      console.log(response);
     // };

     // useEffect(() => {
     //      rohit();
     // }, []);

     const url =
          "https://instagram.fiev22-1.fna.fbcdn.net/v/t50.2886-16/300187312_1106830196926280_4332788947543734004_n.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcy5iYXNlbGluZSIsInFlX2dyb3VwcyI6IltcImlnX3dlYl9kZWxpdmVyeV92dHNfb3RmXCJdIn0&_nc_ht=instagram.fiev22-1.fna.fbcdn.net&_nc_cat=108&_nc_ohc=E-kUixetAxQAX8_SyNP&edm=APU89FABAAAA&vs=1370441863480587_2461160652&_nc_vs=HBksFQAYJEdMQl81QkZJNTdiOHBfNERBUFEyRlhtWEtDRThicV9FQUFBRhUAAsgBABUAGCRHTWtJN1JGalRSXzhXdXNDQURrOExiUGNWWDRvYnFfRUFBQUYVAgLIAQAoABgAGwAVAAAmhInt2vqZjUAVAigCQzMsF0Asd0vGp%2B%2BeGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHX%2BBwA%3D&ccb=7-5&oh=00_AfBicuOZOtXZRwcS38XDIwhnzyrod1PcRQeJIU0C9m87sQ&oe=63B95C99&_nc_sid=86f79a";
     
     const playVideo = `http://localhost:4000/api/v1/insta/show/video?url=${encodeURIComponent(url)}&type=mp4&dlheader=video/mp4&title=test404`;

     return (
          <div>
               {/* <iframe src={imgUrl}></iframe> */}

               {/* <iframe                
               src="https://scontent-waw1-1.cdninstagram.com%2Fv%2Ft51.2885-19%2F65270708_383881482265369_2111109771833638912_n.jpg%3Fstp%3Ddst-jpg_s150x150%26_nc_ht%3Dscontent-waw1-1.cdninstagram.com%26_nc_cat%3D1%26_nc_ohc%3DWdAF0iXkHeAAX-8tcZM%26edm%3DAEF8tYYBAAAA%26ccb%3D7-5%26oh%3D00_AfDEEKlgzx44T9OtJtG8BCA-cav_MAI-Pn2IErtPVd0QBQ%26oe%3D63BB3B20%26_nc_sid%3Da9513d">
               </iframe> */}

               {/* <iframe
                    src="https://www.youtube.com/embed/8RZfZ3qpAMk"
                    title="test"
                    frameBorder="0"
                    className="embed-responsive-item"
               ></iframe> */}

               {/* <video height="550" controls autoPlay>
                    <source src={playVideo} type="video/mp4"></source>
               </video> */}

               <a
                         href={`https://app.igmagnify.com/api/v1/media/download?url=${window.btoa(
                              `https://rr7---sn-ci5gup-qxaes.googlevideo.com/videoplayback?expire=1682099317&ei=FXhCZJ26KpPDjuMP2Jaj8A0&ip=122.161.48.149&id=o-APsrcTEeAjxQF3I7Ukmf2uzfJlpreG4J0p3SjQnuBVm4&itag=18&source=youtube&requiressl=yes&mh=Fr&mm=31%2C26&mn=sn-ci5gup-qxaes%2Csn-cvhelnls&ms=au%2Conr&mv=m&mvi=7&pl=23&initcwndbps=1407500&vprv=1&mime=video%2Fmp4&ns=Vu67l9uSnqq1IYXNy_hSVcYM&cnr=14&ratebypass=yes&dur=104.350&lmt=1664639451267173&mt=1682076792&fvip=2&fexp=24007246&c=WEB&txp=5538434&n=e-d6m1TANwlj8A&sparams=expire%2Cei%2Cip%2Cid%2Citag%2Csource%2Crequiressl%2Cvprv%2Cmime%2Cns%2Ccnr%2Cratebypass%2Cdur%2Clmt&lsparams=mh%2Cmm%2Cmn%2Cms%2Cmv%2Cmvi%2Cpl%2Cinitcwndbps&lsig=AG3C_xAwRQIgEn290jp9lmb0QpSiqntoUxr2iX4ZDF-8kzcHb467E8kCIQCuMx_IB6VDa4Kt0NnVJh1Vhgj01tHuYrv4rqH-3OoMiw%3D%3D&sig=AOq0QJ8wRQIhAL0UkiP_6TuPKoc020nIjMWpb8n4XKku58hh7f62E-aeAiBByd2b86wZ1oI0EIr8W34QXr85nKx0lVlGk56lOI4Www%3D%3D`
                         )}&type=mp4&dlheader=video/mp4`}
                         rel="noreferrer noopener"
                         target="_blank"
                    >
                         Download <i className="bx bx-download label-icon font-size-16 align-middle"></i>
                    </a>
          </div>
     );
};

export default Test;

import React, { useEffect, useState } from "react";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { InstagramTypes } from "src/store/instagram/actionType";
import { getInstaUserInfo } from "src/store/instagram/action";
import SearchUser from "./SearchUser"

const InstaSearch = () => {
  const navHistory = useHistory()
  const dispatch = useDispatch()

     const { isAuthenticated } = useSelector((state: any) => state.login);
     
     useEffect(() => {      

         if(isAuthenticated === false){
               navHistory.push("/login");
         } 

     }, [navHistory, isAuthenticated]);

  document.title = "Search Profile - Igmagnify.com";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Insta" breadcrumbItem="Search Profile" />
          
          <SearchUser />
          
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InstaSearch;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//Import Icons
import Icon from "@ailibs/feather-react-ts";

// Redux Store
import { showRightSidebarAction } from "../../store/actions";

//import component
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import LightDark from "../CommonForBoth/Menus/LightDark";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Input, FormFeedback, Form } from "reactstrap";

//import images
import logoLight from "../../assets/images/ig-logo-light.png";
import logoDark from "../../assets/images/ig-logo-dark.png";
import logoSvg from "../../assets/images/ig-favicon.png";
const socialzinger = "https://www.socialzinger.com/assets/img/favicon.png";
const vidloder = "https://www.vidloder.com/assets/img/favicon.png";
import Igmagnify from "../../assets/images/favicon.svg";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearInstaErrors, getInstaUserID } from "src/store/instagram/action";
import { InstagramTypes } from "src/store/instagram/actionType";

const Header = (props: any) => {

     const dispatch = useDispatch();

     const { layoutMode, showRightSidebar } = useSelector((state: any) => ({
          layoutMode: state.Layout.layoutMode,
          showRightSidebar: state.Layout.ShowRightSidebar,
     }));

     const [search, setsearch] = useState<boolean>(false);
     const [socialDrp, setsocialDrp] = useState<boolean>(false);
     const [isClick, setClick] = useState<boolean>(true);

     /*** Sidebar menu icon and default menu set */
     function tToggle() {
          var body = document.body;
          setClick(!isClick);
          if (isClick === true) {
               body.classList.add("sidebar-enable");
               document.body.setAttribute("data-sidebar-size", "sm");
          } else {
               body.classList.remove("sidebar-enable");
               document.body.setAttribute("data-sidebar-size", "lg");
          }
     }
     
     return (
          <React.Fragment>
               <header id="page-topbar">
                    <div className="navbar-header">
                         <div className="d-flex">
                              <div className="navbar-brand-box">
                                   <Link to="/dashboard" className="logo logo-dark">
                                        <span className="logo-sm">
                                             <img src={logoSvg} alt="" height="" />
                                        </span>
                                        <span className="logo-lg">
                                             <img src={logoDark} alt="" height="" />{" "}
                                             {/* <span className="logo-txt">IGMAGNIFY</span> */}
                                        </span>
                                   </Link>

                                   <Link to="/dashboard" className="logo logo-light">
                                        <span className="logo-sm">
                                             <img src={logoSvg} alt="" height="" />
                                        </span>
                                        <span className="logo-lg">
                                             <img src={logoLight} alt="" height="" />{" "}
                                             {/* <span className="logo-txt">Igmagnify</span> */}
                                        </span>
                                   </Link>
                              </div>

                              <button
                                   onClick={() => {
                                        tToggle();
                                   }}
                                   type="button"
                                   className="btn btn-sm px-3 font-size-16 header-item"
                                   id="vertical-menu-btn"
                              >
                                   <i className="fa fa-fw fa-bars"></i>
                              </button>

                              {/* <Form
                                   onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                   }}
                                   className="app-search d-none d-lg-block"
                              >
                                   <div className="position-relative">
                                        <Input
                                             name="searchText"
                                             type="text"
                                             className="form-control"
                                             placeholder="Search..."
                                             id="validationCustom01"
                                             onChange={validation.handleChange}
                                             onBlur={validation.handleBlur}
                                            value={validation.values.searchText || ""}
                                             invalid={
                                                  validation.touched.searchText && validation.errors.searchText
                                                       ? true
                                                       : false
                                             }
                                        />
                                        {validation.touched.searchText && validation.errors.searchText ? (
                                             <FormFeedback type="invalid">{validation.errors.searchText}</FormFeedback>
                                        ) : null}

                                        <button className="btn btn-primary" type="submit">
                                             <i className="bx bx-search-alt align-middle"></i>
                                        </button>
                                   </div>
                                   
                              </Form> */}
                         </div>
                         <div className="d-flex">
                              <div className="dropdown d-inline-block d-lg-none ms-2">
                                   <button
                                        onClick={() => {
                                             setsearch(!search);
                                        }}
                                        type="button"
                                        className="btn header-item noti-icon "
                                        id="page-header-search-dropdown"
                                   >
                                        <Icon name="search" className="icon-lg" />
                                   </button>
                                   <div
                                        className={
                                             search
                                                  ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                                                  : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                        }
                                        aria-labelledby="page-header-search-dropdown"
                                   >
                                        <form className="p-3">
                                             <div className="form-group m-0">
                                                  <div className="input-group">
                                                       <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search ..."
                                                            aria-label="Recipient's username"
                                                       />
                                                       <div className="input-group-append">
                                                            <button className="btn btn-primary" type="submit">
                                                                 <i className="mdi mdi-magnify" />
                                                            </button>
                                                       </div>
                                                  </div>
                                             </div>
                                        </form>
                                   </div>
                              </div>

                              {/* <LanguageDropdown /> */}

                              {/* light / dark mode */}
                              <LightDark layoutMode={layoutMode} onChangeLayoutMode={props.onChangeLayoutMode} />

                              <Dropdown
                                   className="d-none d-lg-inline-block ms-1"
                                   isOpen={socialDrp}
                                   toggle={() => {
                                        setsocialDrp(!socialDrp);
                                   }}
                              >
                                   <DropdownToggle className="btn header-item noti-icon " tag="button">
                                        <Icon name="grid" className="icon-lg" />
                                   </DropdownToggle>
                                   <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                                        <div className="p-2">
                                             <Row className="g-0">
                                                  <Col>
                                                       <Link className="dropdown-icon-item" to="#">
                                                            <img src={socialzinger} alt="Github" />
                                                            <span>Socialzinger</span>
                                                       </Link>
                                                  </Col>
                                                  <Col>
                                                       <Link className="dropdown-icon-item" to="#">
                                                            <img src={vidloder} alt="bitbucket" />
                                                            <span>Vidloder</span>
                                                       </Link>
                                                  </Col>
                                                  <Col>
                                                       <Link className="dropdown-icon-item" to="#">
                                                            <img src={Igmagnify} alt="dribbble" />
                                                            <span>Igmagnify</span>
                                                       </Link>
                                                  </Col>
                                             </Row>

                                             {/* <Row className="g-0">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dropbox} alt="dropbox" />
                        <span>Dropbox</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={mail_chimp} alt="mail_chimp" />
                        <span>Mail Chimp</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={slack} alt="slack" />
                        <span>Slack</span>
                      </Link>
                    </Col>
                  </Row> */}
                                        </div>
                                   </DropdownMenu>
                              </Dropdown>

                              <NotificationDropdown />
                              <div
                                   onClick={() => {
                                        dispatch(showRightSidebarAction(!showRightSidebar));
                                   }}
                                   className="dropdown d-inline-block"
                              >
                                   <button type="button" className="btn header-item noti-icon right-bar-toggle">
                                        <Icon name="settings" className="icon-lg" />
                                   </button>
                              </div>
                              <ProfileMenu />
                         </div>
                    </div>
               </header>
          </React.Fragment>
     );
};

export default Header;
